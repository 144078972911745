import { db } from "../firebase";
import * as util from "./util";

export const add = (uid: string, message: string, name: string) => {
  return db.collection("feedbacks").add(
    util.addTimeStamp({
      message,
      name,
      uid
    })
  );
};
