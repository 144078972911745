import * as React from "react";
import "../styles/task-editor.css";

interface Props {
  klass: string;
  rawText: string;
  isEditing: boolean;
  updateRawText: (rawText: string) => void;
  unfocus?: () => void;
  scrollPosition: number;
}

class TaskEditor extends React.Component<Props, {}> {
  public static defaultProps() {
    return { klass: "taskEditor" };
  }

  private taskEditor: HTMLTextAreaElement;

  constructor(props: Props) {
    super(props);
    this.onDoubleClick = this.onDoubleClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  public componentDidMount() {
    if (this.props.isEditing) {
      this.taskEditor.focus();
      if (this.props.scrollPosition >= 0) {
        this.taskEditor.scrollTop = this.props.scrollPosition;
      }
    }
  }
  public onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const rawText = e.target.value;
    this.props.updateRawText(rawText);
  }
  public onKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    // Escape keyCode
    if (e.keyCode === 27 && this.props.unfocus) {
      this.props.unfocus();
    }
  }

  public onDoubleClick() {
    if (this.props.unfocus) {
      this.props.unfocus();
    }
  }

  public render() {
    const onRef = (ta: HTMLTextAreaElement) => {
      this.taskEditor = ta;
    };
    // tslint:disable-next-line:no-empty
    const onBlur = () => (this.props.unfocus ? this.props.unfocus() : () => {});
    return (
      <textarea
        ref={onRef}
        className={this.props.klass}
        onDoubleClick={this.onDoubleClick}
        onKeyDown={this.onKeyDown}
        onBlur={onBlur}
        value={this.props.rawText}
        onChange={this.onChange}
        placeholder="write task and click Esc or unfocus to save"
      />
    );
  }
}

export default TaskEditor;
