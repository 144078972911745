import * as React from 'react';
import LoginlessHeader, {  } from "../../header/LoginlessHeader";

import "../../../styles/terms-of-service.css"

export default function TermsOfService() {
  return (
    <div>
      <LoginlessHeader/>
      <div className="terms">
      <h1 id="%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84">利用規約</h1>
      <p>この利用規約（以下，「本規約」といいます。）は，清水 隆之（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。</p>
      <h1 id="%E7%AC%AC1%E6%9D%A1%EF%BC%88%E9%81%A9%E7%94%A8%EF%BC%89">第1条（適用）</h1>
      <ol>
      <li>本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</li>
      <li>当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。</li>
      <li>本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。</li>
      </ol>
      <h1 id="%E7%AC%AC2%E6%9D%A1%EF%BC%88%E5%88%A9%E7%94%A8%E7%99%BB%E9%8C%B2%EF%BC%89">第2条（利用登録）</h1>
      <ol>
      <li>
      <p>本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。</p>
      </li>
      <li>
      <p>当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。</p>
      </li>
      </ol>
      <ul>
      <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
      <li>本規約に違反したことがある者からの申請である場合</li>
      <li>その他，当社が利用登録を相当でないと判断した場合</li>
      </ul>
      <h1 id="%E7%AC%AC3%E6%9D%A1%EF%BC%88%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BCid%E3%81%8A%E3%82%88%E3%81%B3%E3%83%91%E3%82%B9%E3%83%AF%E3%83%BC%E3%83%89%E3%81%AE%E7%AE%A1%E7%90%86%EF%BC%89">第3条（ユーザーIDおよびパスワードの管理）</h1>
      <ol>
      <li>ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを適切に管理するものとします。</li>
      <li>ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。</li>
      <li>ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，当社に故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。</li>
      </ol>
      <h1 id="%E7%AC%AC4%E6%9D%A1%EF%BC%88%E5%88%A9%E7%94%A8%E6%96%99%E9%87%91%E3%81%8A%E3%82%88%E3%81%B3%E6%94%AF%E6%89%95%E6%96%B9%E6%B3%95%EF%BC%89">第4条（利用料金および支払方法）</h1>
      <ol>
      <li>ユーザーは，本サービスの有料部分の対価として，当社が別途定め，本ウェブサイトに表示する利用料金を，当社が指定する方法により支払うものとします。</li>
      <li>ユーザーが利用料金の支払を遅滞した場合には，ユーザーは年14．6％の割合による遅延損害金を支払うものとします。</li>
      </ol>
      <h1 id="%E7%AC%AC5%E6%9D%A1%EF%BC%88%E7%A6%81%E6%AD%A2%E4%BA%8B%E9%A0%85%EF%BC%89">第5条（禁止事項）</h1>
      <p>ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。</p>
      <ol>
      <li>法令または公序良俗に違反する行為</li>
      <li>犯罪行為に関連する行為</li>
      <li>本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為</li>
      <li>当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為</li>
      <li>本サービスによって得られた情報を商業的に利用する行為</li>
      <li>当社のサービスの運営を妨害するおそれのある行為</li>
      <li>不正アクセスをし，またはこれを試みる行為</li>
      <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
      <li>不正な目的を持って本サービスを利用する行為</li>
      <li>本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為</li>
      <li>他のユーザーに成りすます行為</li>
      <li>当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為</li>
      <li>面識のない異性との出会いを目的とした行為</li>
      <li>当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為</li>
      <li>その他，当社が不適切と判断する行為</li>
      </ol>
      <h1 id="%E7%AC%AC6%E6%9D%A1%EF%BC%88%E6%9C%AC%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%AE%E6%8F%90%E4%BE%9B%E3%81%AE%E5%81%9C%E6%AD%A2%E7%AD%89%EF%BC%89">第6条（本サービスの提供の停止等）</h1>
      <ol>
      <li>当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</li>
      <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
      <li>地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合</li>
      <li>コンピュータまたは通信回線等が事故により停止した場合</li>
      <li>その他，当社が本サービスの提供が困難と判断した場合</li>
      <li>当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。</li>
      </ol>
      <h1 id="%E7%AC%AC7%E6%9D%A1%EF%BC%88%E5%88%A9%E7%94%A8%E5%88%B6%E9%99%90%E3%81%8A%E3%82%88%E3%81%B3%E7%99%BB%E9%8C%B2%E6%8A%B9%E6%B6%88%EF%BC%89">第7条（利用制限および登録抹消）</h1>
      <p>当社は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。</p>
      <ol>
      <li>本規約のいずれかの条項に違反した場合</li>
      <li>登録事項に虚偽の事実があることが判明した場合</li>
      <li>料金等の支払債務の不履行があった場合</li>
      <li>当社からの連絡に対し，一定期間返答がない場合</li>
      <li>本サービスについて，最終の利用から一定期間利用がない場合</li>
      <li>その他，当社が本サービスの利用を適当でないと判断した場合</li>
      </ol>
      <p>当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。</p>
      <h1 id="%E7%AC%AC8%E6%9D%A1%EF%BC%88%E9%80%80%E4%BC%9A%EF%BC%89">第8条（退会）</h1>
      <p>ユーザーは，当社の定める退会手続により，本サービスから退会できるものとします。</p>
      <h1 id="%E7%AC%AC9%E6%9D%A1%EF%BC%88%E4%BF%9D%E8%A8%BC%E3%81%AE%E5%90%A6%E8%AA%8D%E3%81%8A%E3%82%88%E3%81%B3%E5%85%8D%E8%B2%AC%E4%BA%8B%E9%A0%85%EF%BC%89">第9条（保証の否認および免責事項）</h1>
      <ol>
      <li>当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。</li>
      <li>当社は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。</li>
      <li>前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。</li>
      <li>当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。</li>
      </ol>
      <h1 id="%E7%AC%AC10%E6%9D%A1%EF%BC%88%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E5%86%85%E5%AE%B9%E3%81%AE%E5%A4%89%E6%9B%B4%E7%AD%89%EF%BC%89">第10条（サービス内容の変更等）</h1>
      <p>当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。</p>
      <h1 id="%E7%AC%AC11%E6%9D%A1%EF%BC%88%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84%E3%81%AE%E5%A4%89%E6%9B%B4%EF%BC%89">第11条（利用規約の変更）</h1>
      <p>当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。</p>
      <h1 id="%E7%AC%AC12%E6%9D%A1%EF%BC%88%E5%80%8B%E4%BA%BA%E6%83%85%E5%A0%B1%E3%81%AE%E5%8F%96%E6%89%B1%E3%81%84%EF%BC%89">第12条（個人情報の取扱い）</h1>
      <p>当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。</p>
      <h1 id="%E7%AC%AC13%E6%9D%A1%EF%BC%88%E9%80%9A%E7%9F%A5%E3%81%BE%E3%81%9F%E3%81%AF%E9%80%A3%E7%B5%A1%EF%BC%89">第13条（通知または連絡）</h1>
      <p>ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。</p>
      <h1 id="%E7%AC%AC14%E6%9D%A1%EF%BC%88%E6%A8%A9%E5%88%A9%E7%BE%A9%E5%8B%99%E3%81%AE%E8%AD%B2%E6%B8%A1%E3%81%AE%E7%A6%81%E6%AD%A2%EF%BC%89">第14条（権利義務の譲渡の禁止）</h1>
      <p>ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。</p>
      <h1 id="%E7%AC%AC15%E6%9D%A1%EF%BC%88%E6%BA%96%E6%8B%A0%E6%B3%95%E3%83%BB%E8%A3%81%E5%88%A4%E7%AE%A1%E8%BD%84%EF%BC%89">第15条（準拠法・裁判管轄）</h1>
      <p>本規約の準拠法は、日本法であり、東京地方裁判所を専属的合意管轄裁判所とします。 本規約は、日本語によるものを正本とします。他の言語による翻訳は、ユーザーの便宜のためにのみ提供されるものに過ぎず、ユーザーとサービス提供者の間で法的に拘束力を有するものは日本語版のみとします。</p>
      <p>以上</p>
      </div>
    </div>
  );
}

