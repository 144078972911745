import { css } from "aphrodite";

import * as React from "react";

import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ThemeContext from "../contexts/ThemeContext";
import FeedbackForm from "../FeedbackForm";
import BaseHeaderPopper from "./BaseHeaderPopper";

import InsertComment from '@material-ui/icons/InsertComment';

export default class HeaderFeedback extends BaseHeaderPopper {
  constructor(props: any) {
    super(props);
  }

  public render() {
    const isOpen = this.state.isOpen;
    return (
      <ThemeContext.Consumer>
        {(theme: any) => (
          <li>
            <Button
              className={css(theme.text)}
              // tslint:disable-next-line:jsx-no-lambda
              buttonRef={node => {
                this.anchorEl = node;
              }}
              aria-owns={isOpen ? "menu-list-grow" : ""}
              aria-haspopup="true"
              onClick={this.handleToggle}
            >
              <InsertComment />
            </Button>
            <Popper open={isOpen} anchorEl={this.anchorEl} placement="bottom-end" transition={true}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: "bottom-left" }}
                >
                  <Paper className={css(theme.view)}>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <FeedbackForm onClickSubmit={this.props.onSendFeedback} />
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </li>
        )}
      </ThemeContext.Consumer>
    );
  }
}
