import * as React from "react";
import * as routes from "../constants/routes";

import { Route, Switch, useLocation } from "react-router-dom";

import PrivacyPolicyJa from "./docs/ja/PrivacyPolicy";
import TermsOfServiceJa from "./docs/ja/TermsOfService";
import PrivacyPolicy from "./docs/PrivacyPolicy";
import TermsOfService from "./docs/TermsOfService";
import Home from "./Home";
// import NotFound from "./NotFound";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Welcome from "./Welcome";
import WithAuthentication from "./WithAuthentication";

import * as ReactGA from "react-ga";

// GA
ReactGA.initialize(
  `${
    process.env.NODE_ENV === "production" ? "UA-165725434-3" : "UA-165725434-4"
  }`,
  { debug: false }
);
const usePageViews = () => {
  const location = useLocation();
  React.useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
};

const App = () => {
  usePageViews(); // GA
  return (
    <div className="l-container">
      <Switch>
        <Route exact={true} path={routes.WELCOME} component={Welcome} />
        <Route exact={true} path={routes.SIGN_IN} component={SignIn} />
        <Route exact={true} path={routes.SIGN_UP} component={SignUp} />
        <Route
          exact={true}
          path={routes.TERMS_OF_SERVICE}
          component={TermsOfService}
        />
        <Route
          exact={true}
          path={routes.PRIVACY_POLICY}
          component={PrivacyPolicy}
        />
        <Route
          exact={true}
          path={routes.TERMS_OF_SERVICE_JA}
          component={TermsOfServiceJa}
        />
        <Route
          exact={true}
          path={routes.PRIVACY_POLICY_JA}
          component={PrivacyPolicyJa}
        />
        <Route component={Home} />
        {/* <Route component={NotFound} /> */}
      </Switch>
    </div>
  );
};
export default WithAuthentication(App);
