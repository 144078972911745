import * as React from "react";
import "../styles/button.css";

interface Props {
  className: string;
  onClick?: (e: React.MouseEvent<any>) => void;
}

const Button: React.StatelessComponent<Props> = ({
  className,
  onClick,
  children,
}) => {
  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};
export default Button;
