import * as React from "react";

import "../styles/checkbox.css";

interface Props {
  index: number;
  class?: string;
  checked: boolean;
  onChange: (e: any) => void;
  value: string;
}

class Checkbox extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  public onClick() {
    this.props.onChange(this.props.value);
  }

  public render() {
    let klass = "checkbox";
    if (this.props.class) {
      klass += " " + this.props.class;
    }

    return (
      <div>
        <input
          id={this.props.index.toString()}
          type="checkbox"
          className={klass}
          checked={this.props.checked}
          onChange={this.onClick}
        />
        <label
          htmlFor={this.props.index.toString()}
          className={klass}
          onClick={this.onClick}
        >
          {this.props.children}
        </label>
      </div>
    );
  }
}

export default Checkbox;
