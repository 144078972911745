import mixpanel from "mixpanel-browser";
import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./components/App";
import MixpanelContext from "./components/contexts/MixpanelContext";
import registerServiceWorker from "./registerServiceWorker";
import "./styles/index.css";

import { BrowserRouter as Router } from "react-router-dom"; // moved because of https://github.com/ReactTraining/react-router/issues/7015

const mixpanelToken =
  process.env.NODE_ENV === "production"
    ? "8191ed0f27450760084ca756da0d9c50"
    : "ef5f539e3c03a7b8dd460ad9ff14a2c2";

mixpanel.init(mixpanelToken);

ReactDOM.render(
  <MixpanelContext.Provider value={mixpanel}>
    <Router>
      <App />
    </Router>
  </MixpanelContext.Provider>,
  document.getElementById("root") as HTMLElement
);
registerServiceWorker();
