import * as React from "react";

export interface Props {
  tabNames: string[];
  activeIndex: number;
  onSwitch: (index: number) => void;
  class?: string;
}

const SwitchTabs = (props: Props) => {
  const tabs = props.tabNames.map((tab: string, index: number) => {
    const style = `container-title ${props.class} ${
      index !== props.activeIndex ? "container-title--unselected" : ""
    }`;
    const onClickTab = () => {
      props.onSwitch(index);
    };
    return (
      <div onClick={onClickTab} className={style} key={index}>
        {tab}
      </div>
    );
  });
  return <div className="container-title-group">{tabs}</div>;
};

export default SwitchTabs;
