import { css } from "aphrodite";
import * as React from "react";

import Checkbox from "../Checkbox";

import ThemeContext from "../contexts/ThemeContext";

import defaultFonts from "../../styles/fonts/defaultFonts";

import '../../styles/font-setting.css';

interface Props {
  currentFontId: number;
  onChangeFontId: (index: number) => void;

}
interface State {
  currentFontId: number;
}

class FontSetting extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentFontId: props.currentFontId,
    };
    this.onChangeFont = this.onChangeFont.bind(this);
  }
  public onChangeFont(fontIdString: string) {
    const fontId = Number(fontIdString);
    this.setState({
      currentFontId: fontId
    });
    this.props.onChangeFontId(fontId);
  }

  public renderFontList() {
    return (
      <ThemeContext.Consumer>
        {(themeConfg: any) => (
        defaultFonts.map((font, i) => {
          return (
            <div key={i} className="font-setting__cell">
          {/* <input
                type="checkbox"
                checked={this.state.currentFontId === i}
                value={i}
                onChange={this.onChangeFont}
                className="font-setting__cell__checkbox"
              /> */}
          <Checkbox
            key={i}
            index={i}
            onChange={this.onChangeFont}
            value={font.id.toString()}
            checked={this.state.currentFontId === Number(font.id)}
            class={css(themeConfg.checkbox)}
          >
            <span style={{ fontFamily: font.fontFamily }}>{font.name}</span>
          </Checkbox>
        </div>
        );
      })
     )}
    </ThemeContext.Consumer>
    )
  }

  public render() {
    const fontList = this.renderFontList();

    return <div className="font-setting">
      <h3>Custom Font Style</h3>
      {fontList}
    </div>;
  }
}

export default FontSetting;
