import { css } from 'aphrodite';
import * as React from "react";

import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import RowStruct from "../models/RowStruct";
import Button from "./Buttton";
import TodoCell from "./TodoCell";
import TotalTimePanel from "./TotalTimePanel";

import ThemeContext from "./contexts/ThemeContext";

import "../styles/task-viewer.css";

interface Props {
  type: string;
  onClick: React.MouseEventHandler<any>;
  todoStruct: RowStruct[];
  onClickCheckbox: (index: number) => void;
  exchangeTodo: (type: string, draggedIndex: number, dropIndex: number) => void;
  onFinishDrag: () => void;
  moveToToday?: (index: number) => void;
  backToTodo?: (index: number) => void;
  onClickClean?: (e: React.MouseEvent<any>) => void;
  onClickReset?: (e: React.MouseEvent<any>) => void;
  onScrollChange: (posiion: number) => void;
}

class TaskViewer extends React.Component<Props, {}> {
  public componentDidMount() {
    this.props.onScrollChange(0);
  }
  public renderTaskCells() {
    let orderedListIndex = 0;

    const taskCells = this.props.todoStruct
      .filter(todo => !!todo.title)
      .map((todo, index): any => {
        if (todo.isList()) {
          orderedListIndex++;
          return (
            <TodoCell
              type={this.props.type}
              key={index}
              id={todo.id}
              todo={todo}
              orderedListIndex={orderedListIndex}
              onClickCheckbox={this.props.onClickCheckbox}
              moveToToday={this.props.moveToToday}
              backToTodo={this.props.backToTodo}
              exchangeTodo={this.props.exchangeTodo}
              onFinishDrag={this.props.onFinishDrag}
            />
          );
        } else {
          // headline and others
          return (
            <TodoCell
              type={this.props.type}
              key={index}
              id={todo.id}
              todo={todo}
              orderedListIndex={orderedListIndex}
              exchangeTodo={this.props.exchangeTodo}
              onFinishDrag={this.props.onFinishDrag}
            />
          );
        }
      }, null);

    return taskCells;
  }

  public renderTodayActions() {
    const todos = this.props.todoStruct.filter((todo: RowStruct) => {
      return todo.isList();
    });

    if (this.props.type === "today" && todos.length !== 0) {
      return (
        <ThemeContext.Consumer>
          {(theme: any) => (
            <div className="task-viewer__today-actions">
              <Button className={`btn ${css(theme.btnDefault)}`} onClick={this.props.onClickClean}>
                Clean
          </Button>
              <Button className={`btn ${css(theme.btnDefault)}`} onClick={this.props.onClickReset}>
                Reset
          </Button>
            </div>
          )}
        </ThemeContext.Consumer>
      );
    }

    return null;
  }

  public render() {
    const cells = this.renderTaskCells();
    const noContent = <div className="task-viewer__no-content">+ add task</div>;
    const onScroll = (e:any) => {
      this.props.onScrollChange(e.target.scrollTop);
    }
    return (
      <div
        onScroll={onScroll}
        onClick={this.props.onClick}
        className={`task-viewer task-viewer--${this.props.type}`}
      >
        {cells.length ? cells : noContent}

        { this.props.type === 'today'
          ? (
              <div className="task-viewer__total-performance">
                <TotalTimePanel
                  todoStruct={this.props.todoStruct}
                />
              </div>
            )
          : null
        }
        {this.renderTodayActions()}
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(TaskViewer);
