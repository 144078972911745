import * as React from 'react';
import LoginlessHeader, {  } from "../header/LoginlessHeader";

import "../../styles/privacy-policy.css"

export default function PrivacyPolicy () {
  return (
    <div>
      <LoginlessHeader/>
      <div className="privacy-policy">
        <h1 id="privacy-policy">privacy policy</h1>
        <p>Takayuki Shimizu (hereinafter referred to as “the Company”) has the following privacy policy regarding the handling of personal information of users in the service provided on this website (hereinafter referred to as “the Service”). (Hereinafter referred to as “this policy”).</p>
        <h1 id="article-1-personal-information">Article 1 (Personal information)</h1>
        <p>“Personal information” refers to “personal information” as referred to in the Personal Information Protection Act, and is information about a living individual, including name, date of birth, address, telephone number, contact information, etc. This refers to information (personal identification information) that can identify a specific individual from such information alone, such as information and appearance that can identify a specific person by description, fingerprint, voiceprint data, and health insurance card insurer number, etc. .</p>
        <h1 id="article-2-how-to-collect-personal-information">Article 2 (How to collect personal information)</h1>
        <p>We may ask for personal information such as name, date of birth, address, telephone number, e-mail address, bank account number, credit card number, and driver's license number when registering for use. Also, transaction records including personal information of users and information regarding payments made between users and partners, including information related to our partners (information providers, advertisers, advertisement distribution destinations, etc.) It is sometimes referred to as “partners”).</p>
        <h1 id="article-3-purpose-of-collecting-and-using-personal-information">Article 3 (Purpose of collecting and using personal information)</h1>
        <p>The purpose of collecting and using personal information is as follows.</p>
        <ol>
        <li>To provide and operate our services</li>
        <li>To respond to user inquiries (including verifying identity)</li>
        <li>To send e-mails about new functions, update information, campaigns, etc. of users' services and other services provided by our company.</li>
        <li>For maintenance and important notices as necessary</li>
        <li>To identify users who have violated the Terms of Service or who intend to use the service for fraudulent or unfair purposes, and refuse to use them.</li>
        <li>To allow users to view, change, delete, or view the usage status of their registration information</li>
        <li>To charge users for usage fees for paid services</li>
        <li>Purposes associated with the above purposes</li>
        </ol>
        <h1 id="article-4-change-of-purpose-of-use">Article 4 (Change of purpose of use)</h1>
        <ol>
        <li>The Company shall change the purpose of use of personal information only if the purpose of use is reasonably recognized to be relevant to the previous purpose.</li>
        <li>When the purpose of use is changed, the purpose after the change shall be notified to the user or announced on this website by the method prescribed by the Company.</li>
        </ol>
        <h1 id="article-5-provision-of-personal-information-to-a-third-party">Article 5 (Provision of personal information to a third party)</h1>
        <ol>
        <li>The Company will not provide personal information to a third party without the prior consent of the user, except in the following cases. However, unless otherwise permitted by the Personal Information Protection Law or other laws.
          1.1. When it is necessary for the protection of human life, body or property and it is difficult to obtain the consent of the person
          1.2. When it is particularly necessary to improve public health or promote the sound development of children and it is difficult to obtain the consent of the person
          1.3. In cases where it is necessary for a national institution or a local public entity or a person entrusted by it to cooperate in carrying out the affairs stipulated by laws and regulations, with the consent of the principal, the execution of the affairs When there is a risk of interference
          1.4. When the following matters have been announced or announced in advance and the Company has notified the Personal Information Protection Committee</li>
        </ol>
        <p>-Including the provision to third parties for the purpose of use
        -Items of data provided to third parties
        -Means or method of provision to third parties
        -Stop providing personal information to third parties at the request of the person
        -How to accept your request
        2. Regardless of the provisions of the preceding paragraph, in the following cases, the information provider is not a third party.
          2.1. When we entrust all or part of the handling of personal information to the extent necessary to achieve the purpose of use
          2.2. When personal information is provided in connection with business succession due to a merger or other reasons
          2.3. When using personal information jointly with a specific person, the fact and the items of personal information used jointly, the scope of the person who uses the personal information, the use of the person who uses the personal information Informing the person in advance of the name or name of the person responsible for the purpose and management of the personal information, or placing the person in an easily accessible state</p>
        <h1 id="article-6-disclosure-of-personal-information">Article 6 (Disclosure of personal information)</h1>
        <ol>
        <li>When the Company requests disclosure of personal information, the Company will disclose this to the individual without delay. However, if any of the following applies to the disclosure, all or part of the disclosure may not be disclosed. If a decision is made not to disclose, notification will be made without delay. In addition, a fee of 3,000 yen will be charged per case for disclosure of personal information.
          1.1. When there is a risk of harming the life, body, property or other rights and interests of the person or a third party
          1.2. When there is a risk of significant hindrance to the proper implementation of our business
          1.3. Other violations of laws and regulations</li>
        <li>Regardless of the provisions of the preceding paragraph, information other than personal information such as history information and characteristic information will not be disclosed in principle.</li>
        </ol>
        <h1 id="article-7-correction-and-deletion-of-personal-information">Article 7 (Correction and deletion of personal information)</h1>
        <ol>
        <li>If the personal information held by the Company is incorrect information, the user shall correct, add or delete the personal information to the Company (hereinafter referred to as “correction, etc.”) according to procedures established by the Company. Can be charged.</li>
        <li>If the Company receives the request from the preceding paragraph from the user and determines that it is necessary to respond to the request, the Company shall correct the personal information without delay.</li>
        <li>The Company will notify the user without delay when corrections are made based on the provisions of the preceding paragraph or when it is decided not to make corrections.</li>
        </ol>
        <h1 id="article-8-suspension-of-use-of-personal-information">Article 8 (Suspension of use of personal information)</h1>
        <ol>
        <li>The Company suspends or deletes the use of personal information for reasons that the personal information is handled beyond the scope of the purpose of use or that it has been obtained by unauthorized means (hereinafter referred to as “ If you are asked to “suspend use, etc.”), we will conduct the necessary investigation without delay.</li>
        <li>If it is determined that it is necessary to respond to the request based on the survey results in the preceding paragraph, the use of the personal information will be suspended without delay.</li>
        <li>The Company will notify the user without delay when suspension of use is made based on the provisions of the preceding paragraph, or when it is decided not to do so.</li>
        <li>Regardless of the preceding two paragraphs, if there is a large amount of expenses for the suspension of use, etc., or other cases where it is difficult to suspend the use, etc., and measures that should be substituted for protecting the rights and interests of users If this is possible, this alternative shall be taken.</li>
        </ol>
        <h1 id="article-9-privacy-policy-changes">Article 9 (Privacy Policy Changes)</h1>
        <ol>
        <li>The contents of this policy can be changed without notifying the user, except for laws and regulations and other matters specified in this policy.</li>
        <li>Except as otherwise specified by the Company, the revised privacy policy will take effect when posted on this website.</li>
        </ol>
        <h1 id="article-10-inquiries">Article 10 (Inquiries)</h1>
        <p>For inquiries regarding this policy, please contact the following office.</p>
        <ul>
        <li>Address: 104-0031 604, 1-18-2 Kyobashi, Chuo-ku, Tokyo</li>
        <li>Company name: Takayuki Shimizu</li>
        <li>Email address: t.shimizu0713@gmail.com</li>
        </ul>
      </div>
    </div>
  );
}

