import * as React from "react";

import Button from "@material-ui/core/Button";
import * as routes from "../constants/routes";
import "../styles/welcome.css";
import Footer from "./Footer";

const Welcome = (props: any) => {
  const redirectToSignIn = () => props.history.push(routes.SIGN_IN);
  const redirectToSignUp = () => props.history.push(routes.SIGN_UP);

  return (
    <div className="welcome">
      <h1>Todo Doc</h1>
      <p>Uo･ｪ･oU</p>
      <p>Document based minimal Todo App for your productivity.</p>
      <Button className="signup-btn" onClick={redirectToSignIn}>
        Sign In
      </Button>
      <Button className="signin-btn" onClick={redirectToSignUp}>
        Sign Up
      </Button>
      <Footer />
    </div>
  );
};

export default Welcome;
