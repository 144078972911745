import * as React from 'react';
import LoginlessHeader, {  } from "../header/LoginlessHeader";

import "../../styles/terms-of-service.css"

export default function TermsOfService() {
  return (
    <div>
      <LoginlessHeader/>
      <div className="terms">
        <h1 id="terms-of-service">Terms of service</h1>
        <p>These Terms of Use (hereinafter referred to as “the Terms”) are the services (hereinafter referred to as “the Services”) provided by Takayuki Shimizu (hereinafter referred to as “the Company”) on this website. ) Terms of use. Registered users (hereinafter referred to as “users”) will use this service in accordance with these Terms.</p>
        <h1 id="article-1-applicable">Article 1 (Applicable)</h1>
        <ol>
        <li>This agreement shall apply to all relationships between the user and our company regarding the use of this service.</li>
        <li>In addition to these Terms, the Company may make various provisions (hereinafter referred to as “Individual Rules”) such as rules for use. These individual provisions, regardless of their names, form part of this Agreement.</li>
        <li>If the provisions of this agreement contradict the provisions of the individual provisions of the preceding article, the provisions of the individual provisions shall take precedence unless otherwise specified in the individual provisions.</li>
        </ol>
        <h1 id="article-2-user-registration">Article 2 (User Registration)</h1>
        <ol>
        <li>
        <p>In this service, the applicant for registration agrees to this agreement, applies for usage registration by the method specified by the Company, and the usage registration is completed when the Company approves it.</p>
        </li>
        <li>
        <p>If the Company determines that the applicant for use registration has the following reasons, it may not approve the application for use registration and shall not be obliged to disclose any reason.
        -When reporting false information when applying for use registration
        -If the application is from someone who has violated these Terms
        -In addition, when the Company determines that usage registration is not appropriate</p>
        </li>
        </ol>
        <h1 id="article-3-user-id-and-password-management">Article 3 (User ID and password management)</h1>
        <ol>
        <li>The user shall appropriately manage the user ID and password of this service at his / her own risk.</li>
        <li>In any case, the user cannot transfer or rent the user ID and password to a third party, or share it with a third party. If the combination of the user ID and password matches the registered information and we log in, we will consider that the user ID is used by the registered user.</li>
        <li>The Company shall not be liable for any damages caused by the use of a user ID and password by a third party, unless the Company has intentional or serious negligence.</li>
        </ol>
        <h1 id="article-4-usage-fee-and-payment-method">Article 4 (Usage fee and payment method)</h1>
        <ol>
        <li>The user shall pay the usage fee displayed separately on the website and specified by the Company as consideration for the paid portion of the Service.</li>
        <li>If the user is late in paying the usage fee, the user shall pay late damages at a rate of 14.6% per year.</li>
        </ol>
        <h1 id="article-5-prohibited-items">Article 5 (Prohibited Items)</h1>
        <p>The user must not perform the following actions when using this service.</p>
        <ol>
        <li>Acts that violate laws or public order and morals</li>
        <li>Acts related to criminal acts</li>
        <li>Actions that infringe copyrights, trademarks, and other intellectual property rights included in the service, such as the contents of the service</li>
        <li>Actions that destroy or interfere with the functions of our company, other users, or other third-party servers or networks</li>
        <li>Commercial use of the information obtained by this service</li>
        <li>Actions that may interfere with the operation of our services</li>
        <li>Unauthorized access or attempts to do so</li>
        <li>Collecting or accumulating personal information related to other users</li>
        <li>Using the Service for an unauthorized purpose</li>
        <li>Actions that cause other users or other third parties of this service to be disadvantaged, damaged, or uncomfortable</li>
        <li>Impersonating other users</li>
        <li>Advertising, advertising, solicitation, or sales activities on this service that we do not permit</li>
        <li>Actions aimed at meeting unfamiliar opposite sex</li>
        <li>Acts that provide direct or indirect benefits to anti-social forces in connection with our services</li>
        <li>Other acts that the Company deems inappropriate</li>
        </ol>
        <h1 id="article-6-suspension-of-provision-of-this-service-etc">Article 6 (Suspension of provision of this service, etc.)</h1>
        <ol>
        <li>The Company shall be able to suspend or interrupt the provision of all or part of the Service without notifying the user in advance if it is determined that there is any of the following reasons.</li>
        <li>When performing maintenance, inspection, or update of the computer system related to this service</li>
        <li>When provision of this service becomes difficult due to force majeure such as an earthquake, lightning, fire, blackout or natural disaster</li>
        <li>When a computer or communication line stops due to an accident</li>
        <li>Other cases where the Company determines that it is difficult to provide this service</li>
        <li>The Company shall not be liable for any disadvantage or damage suffered by users or third parties due to suspension or interruption of the provision of this service.</li>
        </ol>
        <h1 id="article-7-usage-restrictions-and-deregistration">Article 7 (Usage restrictions and deregistration)</h1>
        <p>If the user falls under any of the following conditions, the Company can restrict the use of all or part of the Service to the user or cancel the registration as a user without prior notice. Shall be.</p>
        <ol>
        <li>If you violate any provision of these Terms</li>
        <li>When it becomes clear that there is a false fact in the registered matters</li>
        <li>In case of default of payment obligations such as fees</li>
        <li>When there is no response for a certain period of time from our company</li>
        <li>When this service has not been used for a certain period since the last use</li>
        <li>In addition, when our company determines that the use of this service is not appropriate</li>
        </ol>
        <p>The Company shall not be liable for any damages caused to the user due to the actions performed by the Company based on this section.</p>
        <h1 id="article-8-withdrawal">Article 8 (withdrawal)</h1>
        <p>The user can withdraw from this service by the withdrawal procedure established by the Company.</p>
        <h1 id="article-9-warranty-disclaimer-and-disclaimer">Article 9 (Warranty disclaimer and disclaimer)</h1>
        <ol>
        <li>The Company shall not accept any legal or legal defects (safety, reliability, accuracy, completeness, effectiveness, suitability for a specific purpose, security, etc., errors or bugs, infringement of the service. Etc.) is not expressly or implicitly guaranteed.</li>
        <li>The Company will not be held responsible for any damage caused to the user due to this service. However, this disclaimer does not apply if the contract between the Company and the user concerning this service (including this agreement) is a consumer contract as defined in the Consumer Contract Law.</li>
        <li>Even in the case prescribed in the proviso of the preceding paragraph, the Company shall not suffer damage caused by special circumstances among the damages caused to the user by default or tort by the Company's negligence (excluding gross negligence). (Including the case where the user foresaw or foreseeable the occurrence of damage). In addition, compensation for damages caused to the user due to default or tort by the Company's negligence (excluding gross negligence) shall be limited to the amount of usage fee received from the user in the month in which such damage occurred.</li>
        <li>The Company shall not be liable for any transactions, communications or disputes arising between the user and other users or third parties regarding this service.</li>
        </ol>
        <h1 id="article-10-changes-in-service-content-etc">Article 10 (Changes in service content, etc.)</h1>
        <p>The Company shall be able to change the contents of this service or cancel the provision of this service without notifying the user, and will not be liable for any damage caused to the user by this.</p>
        <h1 id="article-11-changes-to-terms-of-use">Article 11 (Changes to Terms of Use)</h1>
        <p>The Company shall be able to change these Terms at any time without notifying the user when deemed necessary. If you start using this service after changing this agreement, it is assumed that the user has agreed to the revised agreement.</p>
        <h1 id="article-12-handling-of-personal-information">Article 12 (Handling of Personal Information)</h1>
        <p>The Company shall appropriately handle personal information acquired through the use of this service in accordance with the Company's “Privacy Policy”.</p>
        <h1 id="article-13-notification-or-contact">Article 13 (Notification or Contact)</h1>
        <p>Notification or communication between the user and our company shall be made by the method specified by our company. Unless the user submits a change notification in accordance with a method separately determined by the Company, the Company shall notify the contact information that the currently registered contact is valid and notify the contact at the time of transmission. Is considered to have been reached.</p>
        <h1 id="article-14-prohibition-of-assignment-of-rights-and-obligations">Article 14 (Prohibition of assignment of rights and obligations)</h1>
        <p>The user cannot assign the position or the rights or obligations based on this agreement to a third party or use them as collateral without our prior written consent.</p>
        <h1 id="article-15-governing-law-and-jurisdiction">Article 15 (Governing Law and Jurisdiction)</h1>
        <p>The governing law of this agreement is Japanese law, and the Tokyo District Court is the exclusive agreement jurisdiction court. This agreement is written in Japanese. Translations in other languages ​​are provided only for the convenience of the user, and only the Japanese version is legally binding between the user and the service provider.</p>
      </div>
    </div>
  );
}

