import { css } from "aphrodite";
import * as React from "react";

import {
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceCollector,
  DragSourceSpec,
  DropTarget,
  DropTargetCollector,
  DropTargetSpec
} from "react-dnd";

import PerformanceTag from "./PerformanceTag";

import RowStruct from "../models/RowStruct";

import ThemeContext from "./contexts/ThemeContext";

import "../styles/todo-cell.css";

const TODAY_HIGH_PRIORITY_ORDER = 5;
const TODO_MIDDLE_PRIORITY_ORDER = 10;
const TODO_LOW_PRIORITY_ORDER = 20;

interface Props {
  type: string;
  id: number;
  todo: RowStruct;
  orderedListIndex: number;
  exchangeTodo: (type: string, draggedIndex: number, dropIndex: number) => void;
  onFinishDrag: () => void;
  onClickCheckbox?: (index: number) => void;
  moveToToday?: (index: number) => void;
  backToTodo?: (index: number) => void;
  connectDragSource?: ConnectDragSource;
  isDragging?: boolean;
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
  canDrop?: boolean;
}

const TodoCell = (props: Props) => {
  if (!props.connectDragSource || !props.connectDropTarget) {
    return <div />;
  }
  return props.connectDropTarget(props.connectDragSource(createCell(props)));
};

const createCell = (props: Props) => {
  const { type, orderedListIndex, todo } = props;

  if (todo.isList()) {
    const onClickCheckbox = (e: React.MouseEvent<any>) => {
      e.stopPropagation();
      if (props.onClickCheckbox) {
        props.onClickCheckbox(todo.index);
      }
    };
    const onClickMoveToToday = (e: React.MouseEvent<any>) => {
      e.stopPropagation();
      if (props.moveToToday) {
        props.moveToToday(todo.index);
      }
    };
    const onClickBackToTodo = (e: React.MouseEvent<any>) => {
      e.stopPropagation();
      if (props.backToTodo) {
        props.backToTodo(todo.index);
      }
    };

    const moveToTodayButton = (
      <div className="todo-cell__move-to-today" onClick={onClickMoveToToday}>
        ↩
      </div>
    );
    const backToTodoButton = (
      <div className="todo-cell__move-to-today" onClick={onClickBackToTodo}>
        ↪
      </div>
    );
    const isOrderVisible =
      type === "today" && orderedListIndex <= TODAY_HIGH_PRIORITY_ORDER;
    const isLowPriorityInToday =
      type === "today" && orderedListIndex > TODAY_HIGH_PRIORITY_ORDER;
    const topPriority =
      type === "today" && !todo.isHeadline() && orderedListIndex === 1;
    const isMiddlePriorityInTodo =
      type === "todo" && orderedListIndex > TODO_MIDDLE_PRIORITY_ORDER;
      const isLowPriorityInTodo =
      type === "todo" && orderedListIndex > TODO_LOW_PRIORITY_ORDER;
    return (
      <div
        className={`todo-cell
            ${orderedListIndex <= TODAY_HIGH_PRIORITY_ORDER ? " todo-cell--priority" : ""}
            ${isLowPriorityInToday ? " todo-cell--less-visible" : ""}
            ${topPriority ? " todo-cell--top-priority" : ""}
            ${isMiddlePriorityInTodo ? " todo-cell--middle-priority" : ""}
            ${isLowPriorityInTodo ? " todo-cell--low-priority" : ""}
            `}
      >
        <div className={` ${ isOrderVisible ? "todo-cell__order" : "" } `}>
          {`${isOrderVisible ? orderedListIndex : ""}`}
        </div>

        <ThemeContext.Consumer>
          {(theme: any) => (
            <div
              className={`todo-cell__checkbox${
                todo.isDone() ? " todo-cell__checkbox--checked" : ""
                } ${css(theme.checkbox)}`}
              onClick={onClickCheckbox}
            />
          )}
        </ThemeContext.Consumer>
        <div className="todo-cell__title">
          {todo.title}
          {todo.performance || todo.estimation ? (
            <PerformanceTag
              estimation={todo.estimation}
              performance={todo.performance}
            />
          ) : null}
        </div>
        {type === "todo" ? moveToTodayButton : backToTodoButton}
      </div>
    );
  } else if (todo.isHeadline()) {
    if (todo.ish1()) {
      return <h1>{todo.title}</h1>;
    } else if (todo.ish2()) {
      return <h2>{todo.title}</h2>;
    } else if (todo.ish3()) {
      return <h3>{todo.title}</h3>;
    } else if (todo.ish4()) {
      return <h4>{todo.title}</h4>;
    } else if (todo.ish5()) {
      return <h5>{todo.title}</h5>;
    } else {
      return <div />;
    }
  } else {
    return <div>{todo.title}</div>;
  }
};

/*
  React-dnd
 */
// Drag
const todoCellSource: DragSourceSpec<Props> = {
  beginDrag(props) {
    return props;
  },

  endDrag(props, monitor, component) {
    if (monitor && monitor.didDrop()) {
      props.onFinishDrag();
      return;
    }
  }
};

const dragScourceCollect: DragSourceCollector = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
};

// Drop
const todoCellTarget: DropTargetSpec<Props> = {
  hover(hoverProps, monitor) {
    if (!hoverProps || !monitor) {
      return;
    }
    const dragItem: { id?: number; } = monitor.getItem();

    if (!dragItem.id) {
      return;
    }

    if (hoverProps.id !== dragItem.id) {
      hoverProps.exchangeTodo(hoverProps.type, dragItem.id, hoverProps.id);
    }
    return;
  },
  canDrop(props): boolean {
    // filter the cell is list type?
    return true;
  },
  drop(props): void {
    return;
  }
};

const DragTargetCollect: DropTargetCollector = (connect, monitor) => {
  return {
    canDrop: monitor.canDrop(),
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};

// Wrap
export default DropTarget("TODO_CELL", todoCellTarget, DragTargetCollect)(
  DragSource("TODO_CELL", todoCellSource, dragScourceCollect)(TodoCell)
);
