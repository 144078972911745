export default [
  {
    "id": "1",
    "name": "Helvetica",
    "fontFamily": "Helvetica",
    "category": "sans serif",
    "language": "All Languages",
    "group": "classic",
    "displayOrder": "1"
  },
  {
    "id": "2",
    "name": "Bodoni",
    "fontFamily": "Bodoni",
    "category": "serif",
    "language": "All Languages",
    "group": "classic",
    "displayOrder": "2"
  },
  {
    "id": "3",
    "name": "Times New Roman",
    "fontFamily": "Times New Roman",
    "category": "serif",
    "language": "All Languages",
    "group": "classic",
    "displayOrder": "3"
  },
  {
    "id": "4",
    "name": "Futura",
    "fontFamily": "Futura",
    "category": "sans serif",
    "language": "All Languages",
    "group": "classic",
    "displayOrder": "4"
  },
  {
    "id": "5",
    "name": "Garamond",
    "fontFamily": "Garamond",
    "category": "serif",
    "language": "All Languages",
    "group": "classic",
    "displayOrder": "5"
  },
  {
    "id": "6",
    "name": "Georgia",
    "fontFamily": "Georgia",
    "category": "",
    "language": "All Languages",
    "group": "classic",
    "displayOrder": "6"
  },
  {
    "id": "7",
    "name": "verdana",
    "fontFamily": "verdana",
    "category": "",
    "language": "All Languages",
    "group": "classic",
    "displayOrder": "7"
  },
  {
    "id": "8",
    "name": "Arial",
    "fontFamily": "arial",
    "category": "sans serif",
    "language": "All Languages",
    "group": "classic",
    "displayOrder": "8"
  }
];