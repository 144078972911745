import CircularProgress from "@material-ui/core/CircularProgress";
import grey from "@material-ui/core/colors/grey";
import * as React from "react";

import "../styles/loading.css";

const Loading = () => {
  return (
    <div className="loading">
      <CircularProgress style={{ color: grey[500] }} />
    </div>
  );
};

export default Loading;
