import * as React from 'react';
import { firebase } from "../firebase";
import AuthUserContext from "./contexts/AuthUserContext";

interface State {
  authUser: any
}

const WithAuthentication = (Component: any) => {
  // https://github.com/Microsoft/TypeScript/issues/6559
  return class extends React.Component<{}, State> {
    private unregisterAuthObserver: () => void;
    constructor(props: {}) {
      super(props);

      this.state = {
        authUser: null
      };

      this.unregisterAuthObserver = firebase.auth.onAuthStateChanged((authUser: any) => {
        this.setState({
          authUser
        });
      });
    }

    public componentWillUnmount() {
      this.unregisterAuthObserver();
    }

    public render() {
      const { authUser } = this.state;

      return (
        <AuthUserContext.Provider value={authUser}>
          <Component />
        </AuthUserContext.Provider>
      );
    }
  }
}

export default WithAuthentication;
