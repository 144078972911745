import { css } from "aphrodite";
import * as React from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import ThemeContext from "./contexts/ThemeContext";

import "../styles/feedback-form.css";

interface Props {
  onClickSubmit: (message: string, name: string) => any;
}

interface State {
  isSayingThankYou: boolean;
  name: string;
  message: string;
}

export default class FeedbackForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isSayingThankYou: false,
      message: "",
      name: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
  }

  public handleSubmit() {
    this.props.onClickSubmit(this.state.message, this.state.name).then(() => {
      this.setState(
        {
          isSayingThankYou: true,
          message: "",
          name: "",
        },
        () => {
          setTimeout(() => {
            this.setState({
              isSayingThankYou: false,
            });
          }, 5000);
        }
      );
    });
  }

  public onChangeMessage(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const message = e.target.value;
    this.setState({ message });
  }

  public onChangeName(e: React.ChangeEvent<HTMLInputElement>) {
    const name = e.target.value;
    this.setState({ name });
  }

  public render() {
    if (this.state.isSayingThankYou) {
      return (
        <div className="feedback-form">
          <h3>Thank you for feedback!!</h3>
        </div>
      );
    }
    return (
      <ThemeContext.Consumer>
        {(theme: any) => (
          <div className="feedback-form">
            <h3>Send your feedback!</h3>
            <div>
              <TextField
                className={`feedback-form__form ${css(theme.form)}`}
                multiline={true}
                label="Feedback"
                rows="5"
                margin="normal"
                placeholder="write your feedback here!"
                value={this.state.message}
                onChange={this.onChangeMessage}
              />
            </div>
            <div>
              <TextField
                className={`feedback-form__form ${css(theme.form)}`}
                label="Name (optional)"
                margin="normal"
                value={this.state.name}
                onChange={this.onChangeName}
              />
            </div>
            <Button
              className={`feedback-form__submit ${css(theme.form)}`}
              type="submit"
              value="Submit"
              onClick={this.handleSubmit}
              variant="outlined"
            >
              Submit
            </Button>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}
