import * as React from "react";
import "../styles/estimation-tag.css";

interface Props {
  estimation: number;
  performance: number;
}

const performanceTag = (props: Props) => {
  const addTimeUnit = (val: number) => {
    return val >= 0.5 ? `${val}h` : `${Math.round(val * 60)}m`;
  };
  const performanceWithEstimation = (p: number, e: number) => {
    return e >= 0.5
      ? `${p}/${e}h`
      : `${Math.round(p * 60)}/${Math.round(e * 60)}m`;
  };

  let performancelabel;
  if (props.estimation && props.performance) {
    performancelabel = performanceWithEstimation(
      props.performance,
      props.estimation
    );
  } else if (props.estimation) {
    performancelabel = `0/${addTimeUnit(props.estimation)}`;
  } else if (props.performance) {
    performancelabel = addTimeUnit(props.performance);
  } else {
    return null;
  }

  return <span className="estimation-tag">{performancelabel}</span>;
};
export default performanceTag;
