import * as React from 'react';
import * as ReactRouter from "react-router";
import { withRouter } from "react-router-dom";
import * as routes from "../constants/routes";
import { firebase } from "../firebase";
import AuthUserContext from "./contexts/AuthUserContext";
import MixpanelContext from "./contexts/MixpanelContext"

interface Props extends ReactRouter.RouteComponentProps<{}> {
}
const withAuthorization = (authCondition: (authUser: any) => boolean) => (Component: React.ComponentType<any>) => {
  class Authorization extends React.Component<Props, any> {
    public componentDidMount() {
      firebase.auth.onAuthStateChanged((authUser) => {
        if (!authCondition(authUser)) {
          this.props.history.push(routes.SIGN_IN);
        }
      });
    }
    public render() {
      return (
        <MixpanelContext.Consumer>
          {(mixpanel) =>
              <AuthUserContext.Consumer>
                {(authUser) => authUser ? <Component authUser={authUser} mixpanel={mixpanel} /> : null}
              </AuthUserContext.Consumer>
          }
        </MixpanelContext.Consumer>
      )
    }
  }
  return withRouter(Authorization);
}

export default withAuthorization;
