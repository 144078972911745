import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/performance";
import prodConfig from "./config/production";
import stagingConfig from "./config/staging";

const config =
  process.env.NODE_ENV === "production" ? prodConfig : stagingConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
  firebase.performance();
}

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions;

export { db, auth, functions };
