import * as React from "react";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Footer from "./Footer";
import LoginlessHeader from "./header/LoginlessHeader";

import { StyledFirebaseAuth } from "react-firebaseui";
import { Link } from "react-router-dom";
import { auth, firebase, uiConfig } from "../firebase";

import "../styles/signin.css";

import * as routes from "../constants/routes";

interface State {
  email: string;
  password: string;
  errors: any;
  loading: boolean;
}

const INITIAL_STATE = {
  email: "",
  errors: [],
  loading: false,
  password: "",
};

class SignIn extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = INITIAL_STATE;
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  public async onSubmit() {
    await this.setState({ errors: [], loading: true });

    // FIXME: values => length of undefined. so default val is set []
    if (this.isValid()) {
      auth
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .then(() => {
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(routes.APP);
        })
        .catch((error) => {
          const errors = this.state.errors;
          errors.login = ` failure. ${error.message}`;
          this.setState({ errors, loading: false });
        });
    } else {
      this.setState({ loading: false });
    }
  }

  public isValid() {
    const emailValid = this.validateEmail();
    const passwordValid = this.validatePassword();
    return emailValid && passwordValid;
    // let isValid = true;

    // Object.keys(this.state.errors).forEach((key: string) => {
    //   if (this.state.errors[key]) {
    //     isValid = false;
    //   }
    // });
    // return isValid;
  }

  // duplicate with signup page
  public validateEmail() {
    const emailValid = this.state.email.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );

    const errors = this.state.errors;
    errors.email = emailValid ? "" : " is invalid";
    this.setState({ errors });

    return emailValid;
  }

  // duplicate with signup page
  public validatePassword() {
    const passwordValid = this.state.password.length >= 6;

    const errors = this.state.errors;
    errors.password = passwordValid ? "" : " is too short";
    this.setState({ errors });

    return passwordValid;
  }

  public handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const fieldName = e.target.name;
    const value = e.target.value;
    if (fieldName === "email") {
      this.setState({ email: value });
    } else if (fieldName === "password") {
      this.setState({ password: value });
    }
  }

  public render() {
    const errors = Object.keys(this.state.errors)
      .filter((key: string) => this.state.errors[key])
      .map((key: string, id: number) => {
        return (
          // tslint:disable-next-line:jsx-key
          <div key={id} className="signin__form__error">
            {key}
            {this.state.errors[key]}
          </div>
        );
      });
    return (
      <div className="l-loginless-container">
        <LoginlessHeader />
        <div className="signin">
          <div className="signin__title">Log In</div>
          <p>Welcome back Uo･ｪ･oU</p>
          <div className="signin__wrap">
            <div className="signin__google-auth">
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth}
              />
            </div>
            <Divider />
            <form className="signin__form" autoComplete="off">
              <div>
                <TextField
                  name="email"
                  label="Email"
                  className="signin__form__input"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <TextField
                  name="password"
                  label="Password"
                  type="password"
                  className="signin__form__input"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </div>
              {errors}
              <div />
              <div>
                <Button
                  variant="outlined"
                  className="signin__form__submit"
                  onClick={this.onSubmit}
                >
                  {`${
                    this.state.loading
                      ? "Logging in..."
                      : "Log in to your account"
                  }`}
                </Button>
              </div>
              <div>
                <Link to="/signup">Do not have an account? Sign up</Link>
              </div>
            </form>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default SignIn;
