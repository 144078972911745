import { auth } from "./firebase";

export const onAuthStateChanged = (cb: any) => {
  return auth.onAuthStateChanged(cb);
};

export const signOut = () => auth.signOut();

export const currentUser = () => {
  return auth.currentUser;
}

// Sign Up
export const createUserWithEmailAndPassword = (email: string, password: string) => {
  return auth.createUserWithEmailAndPassword(email, password);
}

// Sign In
export const signInWithEmailAndPassword = (email: string, password: string) => {
  return auth.signInWithEmailAndPassword(email, password);
}

export const doPasswordReset = (email: string) =>
  auth.sendPasswordResetEmail(email);

// export const doPasswordUpdate = (user, password: string) =>
//   auth.currentUser.updatePassword(password);
