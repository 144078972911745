import {
  StyleSheet
} from "aphrodite";

const defaultFontFamily = `游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif`;

const styleSheetCreate = (theme, fontFamily) => {
  if (fontFamily) {
    fontFamily = `${fontFamily}, ${defaultFontFamily}`;
  } else {
    fontFamily = defaultFontFamily;
  }

  return StyleSheet.create({
    btnDefault: {
      borderColor: theme.foreground
    },
    checkbox: {
      ":after": {
        border: `1px solid ${theme.primary}`
      },
      ":before": {
        borderBottom: `3px solid ${theme.primary}`,
        borderRight: `3px solid ${theme.primary}`
      },
    },
    form: {
      backgroundColor: theme.background,
      borderColor: theme.foreground,
      color: theme.foreground
    },
    list: {
      ":before": {
        color: theme.primary,
        content: "• "
      }
    },
    view: {
      fontFamily: fontFamily,
      backgroundColor: theme.background,
      color: theme.foreground
    },
    text: {
      fontFamily: fontFamily,
      color: theme.foreground
    },
    switchtabs: {
      fontFamily: fontFamily,
      borderBottom: `1px solid ${theme.primary}`
    }
  });
};
export default styleSheetCreate;
