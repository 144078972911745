export default `
# Tutorial:Welcome to Todo Doc!!!
##### (if you want quick start, click any sentences, select all and delete them. you can see again on 'menu' > 'quick start guide')
## About
- [0] Todo Doc is a document based minimal todo management app for your productivity.
- [0] Todo Doc is designed by many tips for focusing important task, keeping simple.
- [0] you can manage todo with minimum tool stress (like just using plain note) in multi platform.
## Turtorial
### 1. add todo
- [0] Click any list area to change View mode to Edit mode.
- [0] Write your task with no decoration and click Escape(Esc) key.
- [0] It will be listed as todo item with list-checkbox format.
- [0] As you did, you can back to View mode from Edit mode by Esc key.
### 2. make done
- [0] Toggle checkbox when you finish the task
### 3. move todo
- [0] Change order: drag and drop the task to change the order
- [0] Change area: you can move item between today and todo by clicking ↪ or ↩ which show with mouse hover on item
### 4. Today area functions
- [0] Clean button: remove all done items in today to done list by one click. so let you click one item and select clean btn!
- [0] Reset button: clean and move the rest items to the top of todo list.
### 5. Other
- [0] Scroll or cmd+↓ to see done list
- [0] [Tips] use headline style with markdown style (use some #s).
- [0] [Tips] you can change edit mode to view mode by clicking outside of avtive textarea.
- [0] [Tips] estimation tag: you can input estimated time to each task with e:{number}{unit} format (please click here and see as example). The total amount is shown on the bottom. e:1m
- [0] [Tips] performance tag: you can input performance time to each task with p:{number}{unit} format (please click here and see as example). The total amount is shown on the bottom. p:1m
 `;
