import { css } from "aphrodite";
import * as React from "react";
import RowStruct from "../models/RowStruct";
import "../styles/button.css";
import "../styles/done-list-viewer.css";
import PerformanceTag from "./PerformanceTag";
import TotalTimePanel from "./TotalTimePanel";

import ThemeContext from "./contexts/ThemeContext";

const WEEKDAY = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

interface Props {
  todoStruct: RowStruct[];
}

const DoneListViewer: React.StatelessComponent<Props> = ({
  todoStruct,
  children,
}) => {
  const dailyDoneMapping = {};
  todoStruct.forEach((todo: RowStruct) => {
    if (todo.finishedDate) {
      if (!dailyDoneMapping[todo.finishedDate]) {
        dailyDoneMapping[todo.finishedDate] = [];
      }
      dailyDoneMapping[todo.finishedDate].push(todo);
    }
  });

  const panels = Object.keys(dailyDoneMapping)
    .sort((a, b) => {
      a = a.toString().toLowerCase();
      b = b.toString().toLowerCase();
      if (a === b) {
        return 0;
      }
      return a < b ? 1 : -1;
    })
    .map((dateKey, i) => {
      return (
        <DoneDailyPanel
          key={i}
          date={dateKey}
          todoStruct={dailyDoneMapping[dateKey]}
        />
      );
    });

  return <div className="done-list-viewer">{panels}</div>;
};

interface PanelProps {
  date: string;
  todoStruct: RowStruct[];
}

const DoneDailyPanel: React.StatelessComponent<PanelProps> = (
  props: PanelProps
) => {
  const cells = props.todoStruct.map((todo: RowStruct, i) => {
    return (
      // tslint:disable-next-line:jsx-key
      <ThemeContext.Consumer>
        {(theme: any) => (
          <li className={css(theme.list)} key={i}>
            {todo.title}
            <PerformanceTag
              estimation={todo.estimation}
              performance={todo.performance}
            />
          </li>
        )}
      </ThemeContext.Consumer>
    );
  });

  return (
    <div className="done-list-viewer__daily-panel">
      <h5>
        {props.date} {WEEKDAY[new Date(props.date).getDay()].substr(0, 3)}
      </h5>
      <ul>{cells}</ul>
      <div className="done-list-viewer__total-time">
        <TotalTimePanel todoStruct={props.todoStruct} />
      </div>
    </div>
  );
};
export default DoneListViewer;
