import { css } from "aphrodite";

import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import * as React from "react";

import { db } from "../firebase";

import RowStruct from "../models/RowStruct";

import ThemeContext from "./contexts/ThemeContext";

import DoneListViewer from "./DoneListViewer";
import Header from "./header/Header";
import Loading from "./Loading";
import SwitchTabs from "./SwitchTabs";
import TaskEditor from "./TaskEditor";
import TaskViewer from "./TaskViewer";
import withAuthorization from "./withAuthorization";

import "../styles/home.css";

import * as themes from "../styles/themes";
import styleSheetCreate from "../styles/themes/styleSheetGenerator";

import defaultFonts from "../styles/fonts/defaultFonts";

interface State {
  currentFontId: number;
  currentTheme: string;
  doneRawText: any | string;
  doneStruct: any | RowStruct[];
  isDoneEditing: boolean;
  isLoading: boolean;
  isRemoteSyncReserved: boolean;
  isTodayEditing: boolean;
  isTodoEditing: boolean;
  todayRawText: any | string;
  todayStruct: any | RowStruct[];
  todoRawText: any | string;
  todoStruct: any | RowStruct[];
  todayScrollPosition: number;
  todoScrollPosition: number;
  todayTabAcitiveIndex: number;
  todoTabAcitiveIndex: number;
}

interface Props {
  authUser: any;
  mixpanel: any;
}

class Home extends React.Component<Props, State> {
  private board: firebase.firestore.QueryDocumentSnapshot;
  constructor(props: Props) {
    super(props);

    this.state = {
      currentFontId: 1, // TODO sync to server
      currentTheme: "mono", // TODO sync to server
      doneRawText: "",
      doneStruct: [],
      isDoneEditing: false,
      isLoading: false,
      isRemoteSyncReserved: false,
      isTodayEditing: false,
      isTodoEditing: false,
      todayRawText: "",
      todayScrollPosition: 0,
      todayStruct: [],
      todayTabAcitiveIndex: 0,
      todoRawText: "",
      todoScrollPosition: 0,
      todoStruct: [],
      todoTabAcitiveIndex: 0,
    };

    this.unfocusTodo = this.unfocusTodo.bind(this);
    this.unfocusToday = this.unfocusToday.bind(this);

    this.updateTodayRawText = this.updateTodayRawText.bind(this);
    this.updateTodoRawText = this.updateTodoRawText.bind(this);

    this.toggleTodayCheckbox = this.toggleTodayCheckbox.bind(this);
    this.toggleTodoCheckbox = this.toggleTodoCheckbox.bind(this);

    this.syncTodayToRawText = this.syncTodayToRawText.bind(this);
    this.syncTodoToRawText = this.syncTodoToRawText.bind(this);

    this.moveToToday = this.moveToToday.bind(this);
    this.backToTodo = this.backToTodo.bind(this);

    this.exchangeTodo = this.exchangeTodo.bind(this);
    this.finishDragOnToday = this.finishDragOnToday.bind(this);
    this.finishDragOnTodo = this.finishDragOnTodo.bind(this);

    this.sendFeedback = this.sendFeedback.bind(this);

    this.onClickClean = this.onClickClean.bind(this);
    this.onClickReset = this.onClickReset.bind(this);

    this.onChangeFontId = this.onChangeFontId.bind(this);
    this.onChangeTheme = this.onChangeTheme.bind(this);

    this.onChangeTodayScrollPosition = this.onChangeTodayScrollPosition.bind(
      this
    );
    this.onChangeTodoScrollPosition = this.onChangeTodoScrollPosition.bind(
      this
    );

    this.onTodayTabSwitch = this.onTodayTabSwitch.bind(this);
    this.onTodoTabSwitch = this.onTodoTabSwitch.bind(this);
  }

  /***** Initialization *****/

  public componentDidMount() {
    this.initBoard();
    this.props.mixpanel.identify(this.props.authUser.uid);
    this.props.mixpanel.track("app");
  }

  /*
    初期化処理。
    ボードがなければつくり、あればとってくる。
    設定情報がなければデフォルトをつくる。
    取得した情報をstateに保存する。
  */
  public async initBoard() {
    const authUser = this.props.authUser;

    await db.boards.initFirstBoard(authUser.uid);
    const board = await db.boards.findOne(this.props.authUser.uid);

    // Migration
    // 2018/12/24 add board > customSetting > {fontId: number, theme: string}
    if (!board.data().customSetting) {
      await board.ref.update({
        customSetting: {
          fontId: this.state.currentFontId,
          theme: this.state.currentTheme,
        },
      });
    }
    // sync remote State to local State
    this.syncToLocal(board);
    this.updateDoneList(board.id);

    // listen remote board
    db.boards
      .findById(this.board.id)
      .onSnapshot((boardDoc: firebase.firestore.QueryDocumentSnapshot) => {
        // localから先に同期すべきものがある場合true
        if (this.state.isRemoteSyncReserved) {
          return;
        }
        this.syncToLocal(boardDoc);
      });
    // TODO: unsbscribe
  }

  /***** methods for editor functions *****/

  /*
    doneリストを構造化してstateに保存する
  */
  public async updateDoneList(boardId: string) {
    // fetch and render finished todo
    const finishedTodo = await db.boards.getFinishedTodo(boardId);
    const doneStruct = finishedTodo.map((todo, i) => {
      return new RowStruct(todo.data().rawText, i);
    });
    this.setState({ doneStruct });
  }

  /*
    update系(remote syncあり)のevent handler
  */
  public updateTodoRawText(todoRawText: string, isRemoteSync?: boolean) {
    this.setState({ todoRawText }, () => {
      window.localStorage.setItem("todoRawText", todoRawText);
      if (isRemoteSync) {
        this.syncToRemote();
      }
    });
  }

  public updateTodayRawText(todayRawText: string, isRemoteSync?: boolean) {
    this.setState({ todayRawText }, () => {
      window.localStorage.setItem("todayRawText", todayRawText);
      if (isRemoteSync) {
        this.syncToRemote();
      }
    });
  }

  /*
    forcus/un-forcus時のevent handler(remote syncあり)
  */
  public unfocusTodo() {
    const todoStruct = rawTextToStruct(this.state.todoRawText);
    const todoRawText = structToRawText(todoStruct);
    this.setState(
      {
        isTodoEditing: false,
        todoRawText,
        todoStruct,
      },
      () => {
        this.syncToRemote();
      }
    );
  }
  public unfocusToday() {
    const todayStruct = rawTextToStruct(this.state.todayRawText);
    const todayRawText = structToRawText(todayStruct);
    this.setState(
      {
        isTodayEditing: false,
        todayRawText,
        todayStruct,
      },
      () => {
        this.syncToRemote();
      }
    );
  }

  /*
    plain text -> Object convert
  */
  public updateTodoStruct() {
    const todoStruct = rawTextToStruct(this.state.todoRawText);
    this.setState({ todoStruct });
  }
  public updateTodayStruct() {
    const todayStruct = rawTextToStruct(this.state.todayRawText);
    this.setState({ todayStruct });
  }
  /*
    Object -> plain text convert & remote sync
  */
  public syncTodayToRawText() {
    const todayRawText = structToRawText(this.state.todayStruct);
    const isRemoteSync = true;
    this.updateTodayRawText(todayRawText, isRemoteSync);
  }
  public syncTodoToRawText() {
    const todoRawText = structToRawText(this.state.todoStruct);
    const isRemoteSync = true;
    this.updateTodoRawText(todoRawText, isRemoteSync);
  }

  /*
    done/undoneのステータスを反転させてstate/remoteを更新する
  */
  public toggleTodoCheckbox(index: number) {
    const todo = this.state.todoStruct[index];
    const todayString = todo.isDone() ? null : getDateNow();

    // mixpanel track
    const actionName = todo.isDone() ? "undone" : "done";
    this.props.mixpanel.track(actionName, { listType: "todo" });

    this.setState(
      {
        todoStruct: [
          ...this.state.todoStruct.slice(0, index),
          Object.assign(Object.create(todo), todo, {
            // TODO:
            finishedDate: todo.isDone() ? null : todayString,
            progress: todo.isDone() ? 0 : 1,
            raw: todo.mergeFinishedDateToRawText(todayString),
          }),
          ...this.state.todoStruct.slice(index + 1),
        ],
      },
      () => {
        // need to wait because latest version of todoStruct is need to reflesh/sync rawText.
        this.syncTodoToRawText();
      }
    );
  }
  /*
    done/undoneのステータスを反転させてstate/remoteを更新する
    加えて、doneを下にもっていき、かつdoneの一番上にする。既存の並び順は変えない。
   */
  public toggleTodayCheckbox(index: number) {
    const todo = this.state.todayStruct[index];
    const todayString = todo.isDone() ? null : getDateNow();

    // mixpanel track
    const actionName = todo.isDone() ? "undone" : "done";
    this.props.mixpanel.track(actionName, { listType: "today" });

    this.setState(
      {
        todayStruct: this.refreshIndex(
          this.sortByDone([
            ...this.state.todayStruct.slice(0, index),
            Object.assign(Object.create(todo), todo, {
              finishedDate: todo.isDone() ? null : todayString,
              progress: todo.isDone() ? 0 : 1,
              raw: todo.mergeFinishedDateToRawText(todayString),
            }),
            ...this.state.todayStruct.slice(index + 1),
          ])
        ),
      },
      () => {
        // need to wait because latest version of todoStruct is need to reflesh/sync rawText.
        this.syncTodayToRawText();
      }
    );
  }
  /**
   * todo -> todayへの移動。sortのためidを最大値にする（最後尾になる)
   */
  public moveToToday(index: number) {
    const todo = this.state.todoStruct[index];
    if (!todo) {
      return;
    }

    const lastIndex = this.state.todayStruct.length;
    const maxId = Math.max(
      ...this.state.todayStruct.map((s: RowStruct) => s.id)
    );
    this.setState(
      {
        todayStruct: this.refreshIndex(
          this.sortByDone([
            ...this.state.todayStruct,
            Object.assign(Object.create(todo), todo, {
              id: maxId + 1, // id should be uniq in list. id is used for sorting.
              index: lastIndex,
            }),
          ])
        ),
        todoStruct: this.refreshIndex([
          ...this.state.todoStruct.slice(0, index),
          ...this.state.todoStruct.slice(index + 1),
        ]),
      },
      () => {
        this.syncTodoToRawText();
        this.syncTodayToRawText();
      }
    );
    this.props.mixpanel.track("move to today");
  }

  /**
   * today -> todoへの移動。sortのためidを最大値にする（最後尾になる)
   */
  public backToTodo(index: number) {
    const today = this.state.todayStruct[index];
    if (!today) {
      return;
    }

    const maxId = Math.max(
      ...this.state.todayStruct.map((s: RowStruct) => s.id)
    );
    this.setState(
      {
        todayStruct: this.refreshIndex([
          ...this.state.todayStruct.slice(0, index),
          ...this.state.todayStruct.slice(index + 1),
        ]),
        todoStruct: this.refreshIndex([
          Object.assign(Object.create(today), today, {
            id: maxId + 1, // id should be uniq in list.it's used for sorting.
            index: -1,
          }),
          ...this.state.todoStruct,
        ]),
      },
      () => {
        this.syncTodoToRawText();
        this.syncTodayToRawText();
      }
    );
    this.props.mixpanel.track("back to todo");
  }

  /**
   * hover-event hander when dragging todo cell.
   */
  public exchangeTodo(type: string, draggedId: number, dropId: number) {
    const tagretStateKey = `${type}Struct`;
    const targetTodo = this.state[tagretStateKey];
    const draggedTodo = targetTodo.find(
      (todo: RowStruct) => todo.id === draggedId
    );
    const dropTodo = targetTodo.find((todo: RowStruct) => todo.id === dropId);

    let todos = [
      ...targetTodo.slice(0, draggedTodo.index),
      dropTodo,
      ...targetTodo.slice(draggedTodo.index + 1),
    ];
    todos = [
      ...todos.slice(0, dropTodo.index),
      draggedTodo,
      ...todos.slice(dropTodo.index + 1),
    ];

    const newState = {};
    newState[tagretStateKey] = this.refreshIndex(todos);
    this.setState(newState);
  }

  /**
   * DnDが終わったときの同期＆ログ
   */
  public finishDragOnToday() {
    this.props.mixpanel.track("exchange today todo");
    this.syncTodayToRawText();
  }
  public finishDragOnTodo() {
    this.props.mixpanel.track("exchange todo");
    this.syncTodoToRawText();
  }

  public onChangeTodayScrollPosition(scrollPosition: number) {
    this.setState({ todoScrollPosition: scrollPosition });
  }
  public onChangeTodoScrollPosition(scrollPosition: number) {
    this.setState({ todoScrollPosition: scrollPosition });
  }
  public onTodayTabSwitch(index: number) {
    this.setState({ todayTabAcitiveIndex: index });
  }
  public onTodoTabSwitch(index: number) {
    this.setState({ todoTabAcitiveIndex: index });
  }

  /***** methods for other functions *****/

  public sendFeedback(message: string, name: string) {
    const sendFeedbackMail = firebase
      .functions()
      .httpsCallable("sendFeedbackMail");

    sendFeedbackMail({
      displayname: this.props.authUser.displayname,
      message,
      name,
      uid: this.props.authUser.uid,
    })
      .then((res) => {
        console.warn(res);
      })
      .catch((e) => {
        console.warn(e);
      });

    // pass promise to handle in FeedbackForm component
    return db.feedbacks.add(this.props.authUser.uid, message, name);
  }

  public onClickClean(e: React.MouseEvent<any>) {
    const doneTodos = this.state.todayStruct.filter((todo: RowStruct) => {
      return todo.isDone();
    });
    const undoneTodos = this.state.todayStruct.filter((todo: RowStruct) => {
      return !todo.isDone();
    });

    db.boards.addDone(this.board.id, doneTodos);
    this.setState(
      {
        todayStruct: undoneTodos,
      },
      () => {
        this.syncTodayToRawText();
        this.updateDoneList(this.board.id);
      }
    );
    e.stopPropagation();
    this.props.mixpanel.track("clean");
  }

  public onClickReset(e: React.MouseEvent<any>) {
    const doneTodos = this.state.todayStruct.filter((todo: RowStruct) => {
      return todo.isDone();
    });
    const undoneTodos = this.state.todayStruct.filter((todo: RowStruct) => {
      return !todo.isDone();
    });

    db.boards.addDone(this.board.id, doneTodos);
    this.setState(
      {
        todayStruct: [],
        todoStruct: [...undoneTodos, ...this.state.todoStruct],
      },
      () => {
        this.syncTodoToRawText();
        this.syncTodayToRawText();
        this.updateDoneList(this.board.id);
      }
    );
    e.stopPropagation();
    this.props.mixpanel.track("reset");
  }

  public onChangeFontId(currentFontId: number) {
    if (!defaultFonts.some((font) => Number(font.id) === currentFontId)) {
      return;
    }

    this.setState({ currentFontId });
    this.board.ref.update({
      customSetting: Object.assign({}, this.board.data().customSetting, {
        fontId: currentFontId,
      }),
    });
  }

  public onChangeTheme(theme: string) {
    this.setState({ currentTheme: theme });
    this.board.ref.update({
      customSetting: Object.assign({}, this.board.data().customSetting, {
        theme,
      }),
    });
  }

  public render() {
    // if (this.state.isLoading) {
    if (!this.board) {
      return <Loading />;
    }

    const changeToTodayEditing = (() => {
      this.props.mixpanel.track("edit", { listType: "today" });
      this.setState({ isTodayEditing: true });
    })
    const changeToTodoEditing = (() => {
      this.props.mixpanel.track('edit', { listType: "todo" });
      this.setState({ isTodoEditing: true });
    });

    const currentTheme = themes[this.state.currentTheme];
    const currentFont =
      defaultFonts.find((font) => {
        return Number(font.id) === Number(this.state.currentFontId);
      }) || defaultFonts[0];

    const theme = styleSheetCreate(currentTheme, currentFont.fontFamily);

    return (
      <ThemeContext.Provider value={theme}>
        <Header
          authUser={this.props.authUser}
          currentTheme={this.state.currentTheme}
          onChangeTheme={this.onChangeTheme}
          currentFontId={this.state.currentFontId}
          onChangeFontId={this.onChangeFontId}
          onSendFeedback={this.sendFeedback}
        />
        <div className={`container ${css(theme.view)}`}>
          <div className="main">
            <div className="today-container">
              <SwitchTabs
                tabNames={["Today", "Done"]}
                activeIndex={this.state.todayTabAcitiveIndex}
                onSwitch={this.onTodayTabSwitch}
                class={`${css(theme.switchtabs)}`}
              />
              {this.state.isTodayEditing ? (
                <TaskEditor
                  klass={"task-editor today-editor"}
                  rawText={this.state.todayRawText}
                  isEditing={this.state.isTodayEditing}
                  updateRawText={this.updateTodayRawText}
                  unfocus={this.unfocusToday}
                  scrollPosition={this.state.todayScrollPosition}
                />
              ) : this.state.todayTabAcitiveIndex === 0 ? (
                <TaskViewer
                  type="today"
                  onClick={changeToTodayEditing}
                  todoStruct={this.state.todayStruct}
                  onClickCheckbox={this.toggleTodayCheckbox}
                  backToTodo={this.backToTodo}
                  exchangeTodo={this.exchangeTodo}
                  onFinishDrag={this.finishDragOnToday}
                  onClickClean={this.onClickClean}
                  onClickReset={this.onClickReset}
                  onScrollChange={this.onChangeTodayScrollPosition}
                />
              ) : (
                <DoneListViewer todoStruct={this.state.doneStruct} />
              )}
            </div>
            {/* <div className="done-container">
              <h3>Done</h3>
            </div> */}
          </div>
          <div className="sidebar">
            <div className="todo-container">
              <SwitchTabs
                tabNames={["Todo's"]}
                activeIndex={this.state.todoTabAcitiveIndex}
                onSwitch={this.onTodoTabSwitch}
                class={`${css(theme.switchtabs)}`}
              />
              {this.state.isTodoEditing ? (
                <TaskEditor
                  klass={"task-editor todo-editor"}
                  rawText={this.state.todoRawText}
                  isEditing={this.state.isTodoEditing}
                  updateRawText={this.updateTodoRawText}
                  unfocus={this.unfocusTodo}
                  scrollPosition={this.state.todoScrollPosition}
                />
              ) : (
                <TaskViewer
                  type="todo"
                  onClick={changeToTodoEditing}
                  todoStruct={this.state.todoStruct}
                  onClickCheckbox={this.toggleTodoCheckbox}
                  moveToToday={this.moveToToday}
                  exchangeTodo={this.exchangeTodo}
                  onFinishDrag={this.finishDragOnTodo}
                  onScrollChange={this.onChangeTodoScrollPosition}
                />
              )}
            </div>
          </div>
        </div>
      </ThemeContext.Provider>
    );
  }

  /***** Private methods *****/
  /**
   * just set data from remote tot local state
   */
  private syncToLocal(board: firebase.firestore.QueryDocumentSnapshot) {
    this.board = board;
    const data = board.data();
    const DEFAULT_FONT_ID = 1;

    this.setState(
      {
        // at first time (just after signup), data board.data is emply. so we need to check data.property
        currentFontId: data.customSetting
          ? data.customSetting.fontId
          : DEFAULT_FONT_ID,
        currentTheme: data.customSetting
          ? data.customSetting.theme
          : themes.mono.name,
        doneRawText: data.done,
        todayRawText: data.today,
        todoRawText: data.todo,
      },
      () => {
        this.updateTodoStruct();
        this.updateTodayStruct();
      }
    );
  }
  /**
   * remote sync rules:
   * sync if n second passed from last update
   * sync if editor unfocused
   * sync if threre're diff between state and board document
   */
  private syncToRemote() {
    if (this.state.isRemoteSyncReserved) {
      return;
    }
    // FIXME: streaming update. this.board should be synced remote data.
    //  -> done. this.board synced with remote in realtime.
    const getDiff = () => {
      const updateBoard: any = {};
      const board = this.board.data();
      if (this.state.todoRawText !== board.todo) {
        updateBoard.todo = this.state.todoRawText;
      }
      if (this.state.todayRawText !== board.today) {
        updateBoard.today = this.state.todayRawText;
      }
      if (this.state.doneRawText !== board.done) {
        updateBoard.done = this.state.doneRawText;
      }
      return updateBoard;
    };
    const diff = getDiff();

    // 差分をチェックしてアクセスを抑える
    if (Object.keys(diff).length === 0) {
      return;
    }
    // localからの同期を優先し、remoteから上書きされないようにするため
    // isRemoteSyncReserved=trueにしてremoteにアップデートをかける
    this.setState({ isRemoteSyncReserved: true });

    setTimeout(() => {
      // re-ckeck diff & update
      this.board.ref.update(db.util.addTimeStamp(getDiff()));
      this.setState({ isRemoteSyncReserved: false });
    }, 1000);
  }

  private refreshIndex(todos: RowStruct[]) {
    return todos.map((todo, index) => {
      return Object.assign(Object.create(todo), todo, { index });
    });
  }

  private sortByDone(todos: RowStruct[]) {
    return todos.sort((a: RowStruct, b: RowStruct): number => {
      let comparison = 0;

      if (a.isDone() && b.isDone()) {
        comparison = 0;
      } else if (!a.isDone() && b.isDone()) {
        comparison = -1;
      } else if (a.isDone() && !b.isDone()) {
        comparison = 1;
      } else if (!a.isDone() && !b.isDone()) {
        if (a.index < b.index) {
          comparison = -1;
        } else if (a.index > b.index) {
          comparison = 1;
        } else {
          comparison = 0;
        }
      }

      return comparison;
    });
  }
}

// TODO move to util
const getDateNow = () => {
  const dt = new Date();
  const y = dt.getFullYear();
  const m = ("00" + (dt.getMonth() + 1)).slice(-2);
  const d = ("00" + dt.getDate()).slice(-2);
  const result = y + "/" + m + "/" + d;

  return result;
};

const structToRawText = (todos: RowStruct[]) => {
  return todos
    .map((todo: RowStruct) => {
      return todo.toRawText();
    })
    .join("\n");
};

const rawTextToStruct = (rawText: string) => {
  return rawText
    .split(/\r\n|\r|\n/)
    .map((t: string, index: number) => {
      return new RowStruct(t, index);
    })
    .filter((todo: RowStruct) => {
      // filter rows. It means to delete empty lines.
      return !todo.isOther();
    });
};

const authCondition = (authUser: any) => !!authUser;
export default withAuthorization(authCondition)(Home);
