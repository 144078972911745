import { css } from "aphrodite";
import * as React from "react";

import Checkbox from "../Checkbox";

import ThemeContext from "../contexts/ThemeContext";

import { dark, mono, night, nightBlue, sunrise } from "../../styles/themes";

import "../../styles/theme-setting.css";

// change to array
const THEMES = [dark, mono, night, nightBlue, sunrise];

interface Props {
  currentTheme: string;
  onChangeTheme: (theme: string) => void;

}
interface State {
  currentTheme: string;
}

class ThemeSetting extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentTheme: props.currentTheme
    };
    this.onChangeTheme = this.onChangeTheme.bind(this);
  }

  public onChangeTheme(theme: string) {
    this.setState({
      currentTheme: theme
    });
    this.props.onChangeTheme(theme);
  }

  public render() {
    const themeList = THEMES.map((theme, i) => {

      return (
        <div key={i} className="theme-setting__cell">
          <ThemeContext.Consumer>
            {(themeConfg: any) => (
              <Checkbox
                key={i}
                index={i}
                onChange={this.onChangeTheme}
                value={theme.name}
                checked={this.state.currentTheme === theme.name}
                class={css(themeConfg.checkbox)}
              >
                <span className="theme-setting__cell__colorbox" style={{ color: theme.background, backgroundColor: theme.background }}>◯</span>
                <span className="theme-setting__cell__colorbox" style={{ color: theme.foreground, backgroundColor: theme.foreground }}>◯</span>
                <span className="theme-setting__cell__colorbox" style={{ color: theme.primary, backgroundColor: theme.primary }}>◯</span>
                {` ${theme.name}`}
              </Checkbox>
            )}
          </ThemeContext.Consumer>

        </div>
      );
    });

    return <div className="theme-setting">
      <h3>Custom Color Theme</h3>
      {themeList}
    </div>;
  }
}

export default ThemeSetting;
