import { css } from "aphrodite";

import * as React from "react";

import ThemeContext from "../contexts/ThemeContext";
import HeaderColorCustom from "./HeaderColorCustom";
import HeaderFeedback from "./HeaderFeedback";
import HeaderFontCustom from "./HeaderFontCunstom";
import HeaderMenu from "./HeaderMenu";

import * as Themes from "../../styles/themes";

import "../../styles/header.css";

// interface State {}

interface Props {
  authUser: any;
  currentTheme: string;
  onChangeTheme: (theme: string) => void;
  currentFontId: number;
  onChangeFontId: (index: number) => void;
  onSendFeedback: (message: string, name: string) => void;
}

export default class Header extends React.Component<Props, any> {

  constructor(props: Props) {
    super(props);
  }

  public logoImage() {
    let logo = "tododoc_logo.png";
    const currentTheme = Themes[this.props.currentTheme];
    if (currentTheme && currentTheme.darkMode) {
      logo = "tododoc_logo_inverse.png"
    }
    return (<img className="header__logo" src={logo} alt="" />)
  }

  public render() {

    return (
      <ThemeContext.Consumer>
        {(theme: any) => (
          <div className={`header ${css(theme.view)}`}>
            <h1 className="header__title">
              {this.logoImage()}
            </h1>
            <nav>
              <ul>
                <HeaderMenu authUser={this.props.authUser} />
                {/* <li>layout</li> */}
                <HeaderFeedback
                  onSendFeedback={this.props.onSendFeedback}
                />
                <HeaderFontCustom
                  currentFontId={this.props.currentFontId}
                  onChangeFontId={this.props.onChangeFontId}
                />
                <HeaderColorCustom
                  currentTheme={this.props.currentTheme}
                  onChangeTheme={this.props.onChangeTheme}
                />
              </ul>
            </nav>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}
