import * as React from 'react';
import RowStruct from "../models/RowStruct";

interface Props {
  todoStruct: RowStruct[];
}

const TotalTimePanel: React.SFC<Props> = (props) => {
  let sumOfEstimation = 0;
  let sumOfPreformance = 0;
  let progress;

  props.todoStruct.forEach((todo) => {
    if (todo.estimation) {
      sumOfEstimation += Number(todo.estimation);
    }
    if (todo.performance) {
      sumOfPreformance += Number(todo.performance);
    }
  });

  if (sumOfEstimation <= 0 && sumOfPreformance <= 0) {
    return null;
  }

  sumOfEstimation = Math.round(sumOfEstimation * 100) / 100;
  sumOfPreformance = Math.round(sumOfPreformance * 100) / 100;

  if (sumOfEstimation && sumOfPreformance) {
    progress = Math.round(sumOfPreformance / sumOfEstimation * 100);
  }


  return (
    <div>
      Time {sumOfEstimation ? `${sumOfPreformance}/${sumOfEstimation}h` : `${sumOfPreformance}h`}
      {progress ? ` (${progress}%)` : ""}
    </div>
  );
};

export default TotalTimePanel;
