const LIST_REGEXP = /^-[\x20|\xA0]/;
const CHECKBOX_REGEXP = /^-[\x20|\xA0]\[([x|\x20|\xA0|]|[0-9]{1,2}|[1][0]{2})\][\x20|\xA0]/;
const HEADLINE_REGEXP = /^(#{1,5})[\x20|\xA0]/;
const ESTIMATION_REGEXP = /([\x20|\xA0]e:[0-9]+\.*[0-9]*m[\x20|\xA0]|[\x20|\xA0]e:[0-9]+\.*[0-9]*min[\x20|\xA0]|[\x20|\xA0]e:[0-9]+\.*[0-9]*h[\x20|\xA0]|[\x20|\xA0]e:[0-9]+\.*[0-9]*hour[\x20|\xA0]|[\x20|\xA0]e:[0-9]+\.*[0-9]*hours[\x20|\xA0])/;
const PERFORMANCE_REGEXP = /([\x20|\xA0]p:[0-9]+\.*[0-9]*m[\x20|\xA0]|[\x20|\xA0]p:[0-9]+\.*[0-9]*min[\x20|\xA0]|[\x20|\xA0]p:[0-9]+\.*[0-9]*h[\x20|\xA0]|[\x20|\xA0]p:[0-9]+\.*[0-9]*hour[\x20|\xA0]|[\x20|\xA0]p:[0-9]+\.*[0-9]*hours[\x20|\xA0])/;

const YYYYMMDD_REGEXP = /\d{4}[\/\-](0?[1-9]|1[012])[\/\-]([12][0-9]|3[01]|0?[1-9])/;
const FINISHEDDATE_REGEXP = /[\x20|\xA0]done:\d{4}[\/\-](0?[1-9]|1[012])[\/\-]([12][0-9]|3[01]|0?[1-9])[\x20|\xA0]/;
const FINISHEDDATEFORMAT_REGEXP = /[\x20|\xA0]done:.*[\x20|\xA0]/;

class RowStruct {
  public id: number;
  public title: string;
  public estimation: number;
  public performance: number;
  public index: number;
  public finishedDate: string | null;
  public raw: string;

  private type: string;
  private progress: number;

  constructor(raw: string, index: number) {
    this.id = index + 1;
    this.raw = raw;
    this.index = index;
    this.estimation = 0;
    this.performance = 0;
    this.finishedDate = null;
    this.parse();
  }

  public isList() {
    return this.type === "list";
  }
  public isHeadline() {
    return this.type.match(/^h[1-5]$/);
  }
  public ish1() {
    return this.type === "h1";
  }
  public ish2() {
    return this.type === "h2";
  }
  public ish3() {
    return this.type === "h3";
  }
  public ish4() {
    return this.type === "h4";
  }
  public ish5() {
    return this.type === "h5";
  }
  public isOther() {
    return this.type === "other";
  }
  public isDone() {
    // return this.progress === 1;
    return this.progress === 1 || !!this.finishedDate;
  }
  public toRawText() {
    let rawText = this.title;
    if (this.hasList()) {
      /*
        hide progress.It's not enough valuable function now. 2019/10/14
      */
      // const progress = this.isDone() ? "x" : this.progress;
      // rawText = `- [${progress}] ${this.title}`;
      rawText = `- ${this.title}`;
    }
    const headlineMatched = this.hasHeadline();
    if (headlineMatched) {
      const headlineNumber = headlineMatched[1].length;
      rawText = `${"#".repeat(headlineNumber)} ${this.title}`;
    }
    if (this.hasEstimation()) {
      if (this.estimation >= 0.5) {
        rawText += ` e:${this.estimation}h`;
      } else {
        rawText += ` e:${Math.round(this.estimation * 60)}m`;
      }
    }
    if (this.hasPerformance()) {
      if (this.performance >= 0.5) {
        rawText += ` p:${this.performance}h`;
      } else {
        rawText += ` p:${Math.round(this.performance * 60)}m`;
      }
    }
    if (this.hasFinishedDate()) {
      rawText += ` done:${this.finishedDate}`;
    }

    rawText += " ";

    return rawText;
  }

  // toggleしたときにfinished dateを上書きするため
  public mergeFinishedDateToRawText(finishedDate: string | null) {
    this.finishedDate = finishedDate;

    if (this.hasFinishedDate()) {
      return this.toRawText();
    } else {
      return this.raw + ` done:${finishedDate} `;
    }
  }

  private parseType() {
    if (this.hasList()) {
      this.type = "list";
    } else if (this.hasHeadline()) {
      const matched = this.hasHeadline();
      if (matched) {
        this.type = `h${matched[1].length}`;
      }
    } else if (this.raw.trim().length !== 0) {
      this.type = "list";
      this.raw = `- ${this.raw}`;

    } else {
      this.type = "other";
    }
  }

  private parseProgress() {
    // parse progress
    if (this.hasList()) {
      const matched = this.hasCheckbox();
      if (matched) {
        const progress = matched[1];
        switch (true) {
          case /[0-9]{1,2}/.test(progress):
            this.progress = Number(progress) / 100;
            break;
          case /^[x|100]/.test(progress):
            this.progress = 1;
            break;
          default:
            this.progress = 0;
            break;
        }
      } else {
        this.progress = 0;
      }
    }
  }

  private getTimeNumber(tag: string) {
    const numberMatched = tag.match(/([0-9]+\.*[0-9]*)/);

    if (!numberMatched) {
      return;
    }

    const timeNumber = numberMatched[1];

    if (!timeNumber) {
      return;
    }
    return timeNumber;
  }

  private getTimeUnit(tag: string) {
    const unitMatched = tag.match(
      /(m|min|h|hour|hours)[\x20|\xA0]/
    );

    if (!unitMatched) {
      return;
    }

    const timeUnit = unitMatched[1];
    if (!timeUnit) {
      return;
    }

    return timeUnit;
  }

  private adjustTimewithUnit(timeNumber: string, unit: string) {
    if (/m$|min$/.test(unit)) {
      // min
      return Math.round(Number(timeNumber) / 60 * 100) / 100;
    } else {
      // hour
      return Number(timeNumber);
    }
  }

  private parseEstimation(): void {
    /*
          parse estimation tag: ` e:${number}${unit} `
         */
    const matched = this.hasEstimation();

    if (!matched) { return; }

    const estimationTag = matched[1];


    if (!estimationTag) { return; }

    const estimationNumber = this.getTimeNumber(estimationTag);
    const estimationUnit = this.getTimeUnit(estimationTag)

    if (!estimationNumber || !estimationUnit) {
      return;
    }

    this.estimation = this.adjustTimewithUnit(estimationNumber, estimationUnit);
  }

  private parsePerformance(): void {
    /*
          parse performance tag: ` p:${number}${unit} `
         */
    const matched = this.hasPerformance();

    if (!matched) { return; }

    const performanceTag = matched[1];

    if (!performanceTag) { return; }

    const performanceNumber = this.getTimeNumber(performanceTag);
    const performanceUnit = this.getTimeUnit(performanceTag)

    if (!performanceNumber || !performanceUnit) {
      return;
    }

    this.performance = this.adjustTimewithUnit(performanceNumber, performanceUnit);
  }

  private parseTitle() {
    // clip title
    if (this.isList()) {
      const hasCheckbox = this.hasCheckbox();
      if (hasCheckbox) {
        this.title = this.raw.split(CHECKBOX_REGEXP)[2];
      } else {
        this.title = this.raw.split(LIST_REGEXP)[1];
      }
    } else if (this.isHeadline()) {
      this.title = this.raw.split(HEADLINE_REGEXP)[2];
    } else {
      this.title = this.raw;
    }

    if (this.hasEstimation()) {
      this.title = this.title.replace(ESTIMATION_REGEXP, " ");
    }
    if (this.hasPerformance()) {
      this.title = this.title.replace(PERFORMANCE_REGEXP, " ");
    }
    if (this.hasFinishedDateFormat()) {
      this.title = this.title.replace(FINISHEDDATEFORMAT_REGEXP, "");
    }

    this.title = this.title.replace(/[\x20|\xA0]+$/, "");
  }

  private parseFinishedDate() {
    const matched = this.hasFinishedDate();
    if (!matched) { return; }

    const datematched = matched[0].match(YYYYMMDD_REGEXP);

    if (!datematched) { return; }

    this.finishedDate = datematched[0];
  }

  private parse() {
    this.parseType();
    this.parseProgress();
    this.parseEstimation();
    this.parsePerformance();
    this.parseTitle();
    this.parseFinishedDate();
  }

  private hasList() {
    return this.raw.match(LIST_REGEXP);
  }
  private hasHeadline() {
    return this.raw.match(HEADLINE_REGEXP);
  }
  private hasCheckbox() {
    // - [ ]
    // - [0~99]
    // - [x]
    return this.raw.match(CHECKBOX_REGEXP);
  }
  private hasEstimation() {
    /*
            match(/(
             [\x20|\xA0]e:[0-9]+\..[0-9].m[\x20|\xA0]
             |[\x20|\xA0]e:[0-9]+\..[0-9].min[\x20|\xA0]
             |[\x20|\xA0]e:[0-9]+\..[0-9].h[\x20|\xA0]
             |[\x20|\xA0]e:[0-9]+\..[0-9].hour[\x20|\xA0]
             |[\x20|\xA0]e:[0-9]+\..[0-9].hours[\x20|\xA0]
            )/)
          */
    return this.raw.match(ESTIMATION_REGEXP);
  }
  private hasPerformance() {
    return this.raw.match(PERFORMANCE_REGEXP);
  }
  private hasFinishedDate() {
    return this.raw.match(FINISHEDDATE_REGEXP);
  }
  private hasFinishedDateFormat() {
    return this.raw.match(FINISHEDDATEFORMAT_REGEXP);
  }
}
export default RowStruct;
