import * as React from "react";

interface State {
  isOpen: boolean;
}

export default class BaseHeaderPopper extends React.Component<any, State> {
  public anchorEl: any;
  constructor(props: any) {
    super(props);

    this.state = {
      isOpen: false
    };
    this.anchorEl = null;
    this.handleClose = this.handleClose.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  public handleToggle() {

    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  public handleClose(event: any) {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ isOpen: false });
  }
  public render() {
    return (
      <li/>
    );
  }
}
