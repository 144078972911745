import { css } from "aphrodite";

import * as React from "react";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import * as routes from "../../constants/routes";
import { firebase } from "../../firebase";
import ThemeContext from "../contexts/ThemeContext";

import DehazeIcon from "@material-ui/icons/MoreHoriz";

import BaseHeaderPopper from "./BaseHeaderPopper";

export default withRouter(
  class HeaderMenu extends BaseHeaderPopper {
    constructor(props: any) {
      super(props);

      this.logout = this.logout.bind(this);
    }

    public logout() {
      firebase.auth
        .signOut()
        .then(() => {
          this.props.history.push(routes.SIGN_IN);
        })
        .catch((e) => {
          console.warn(`ERR. fail to signout.${e}`);
        });
    }

    public render() {
      const isOpen = this.state.isOpen;
      return (
        <ThemeContext.Consumer>
          {(theme: any) => (
            <li>
              <Button
                // tslint:disable-next-line:jsx-no-lambda
                buttonRef={(node) => {
                  this.anchorEl = node;
                }}
                className={css(theme.text)}
                aria-owns={isOpen ? "menu-list-grow" : ""}
                aria-haspopup="true"
                onClick={this.handleToggle}
              >
                <DehazeIcon />
              </Button>
              <Popper open={isOpen} anchorEl={this.anchorEl} transition={true}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: "bottom" }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={this.handleClose}>
                        <MenuList>
                          <MenuItem>{this.props.authUser.displayName}</MenuItem>
                          <MenuItem><a target="_blank" href='https://gist.github.com/takayukishmz/052c2b3d696152eed4dc312b662f0060'>Quick Start Guide</a></MenuItem>
                          <MenuItem onClick={this.logout}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </li>
          )}
        </ThemeContext.Consumer>
      );
    }
  }
);
