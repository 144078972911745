import * as React from "react";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import * as routes from "../constants/routes";
import "../styles/signin.css";
import Footer from "./Footer";
import LoginlessHeader from "./header/LoginlessHeader";

import { StyledFirebaseAuth } from "react-firebaseui";
import { Link } from "react-router-dom";
import { auth, firebase, uiConfig } from "../firebase";

interface State {
  acceptTerms: boolean;
  email: string;
  errors: any;
  loading: boolean;
  password: string;
  username: string;
}

const INITIAL_STATE = {
  acceptTerms: false,
  email: "",
  errors: [],
  loading: false,
  password: "",
  username: "",
};

class SignUp extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = INITIAL_STATE;
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * notifyRegistration
   */
  public notifyRegistration(uid: string, displayname: any) {
    const sendRegistrationMail = firebase.functions().httpsCallable("sendRegistrationMail");
    sendRegistrationMail({ uid, displayname });
  }

  public async onSubmit() {
    await this.setState({
      errors: [],
      loading: true,
    });

    // FIXME: values => length of undefined. so default val is set []
    console.warn(this.isValid());

    if (this.isValid()) {
      auth
        .createUserWithEmailAndPassword(this.state.email, this.state.password)
        .then((userCredential) => {
          if (userCredential.user) {
            userCredential.user.updateProfile({
              displayName: this.state.username,
              photoURL: "",
            });
            this.notifyRegistration(userCredential.user.uid, userCredential.user.displayName);
            this.setState({ ...INITIAL_STATE });
            this.props.history.push(routes.APP);
          } else {
            const errors = this.state.errors;
            errors.login = ` Err. user not found in userCredential.`;
            this.setState({ errors, loading: false });
          }
        })
        .catch((error) => {
          const errors = this.state.errors;
          errors.login = ` failure. ${error.message}`;
          this.setState({ errors, loading: false });
        });
    } else {
      this.setState({ loading: false });
    }
  }
  public isValid() {
    const usernameValid = this.validateUsername();
    const emailValid = this.validateEmail();
    const passwordValid = this.validatePassword();
    const acceptTermsValid = this.validateAcceptTerms();
    return usernameValid && emailValid && passwordValid && acceptTermsValid;
  }

  public validateUsername() {
    const usernameValid = this.state.username.length > 0;

    const errors = this.state.errors;
    errors.username = usernameValid ? "" : " Username is empty.";
    this.setState({ errors });

    return usernameValid;
  }

  public validateEmail() {
    const emailValid = this.state.email.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );

    const errors = this.state.errors;
    errors.email = emailValid ? "" : " Email is invalid.";
    this.setState({ errors });

    return emailValid;
  }

  public validatePassword() {
    const passwordValid = this.state.password.length >= 6;

    const errors = this.state.errors;
    errors.password = passwordValid ? "" : " Password is too short.";
    this.setState({ errors });

    return passwordValid;
  }

  public validateAcceptTerms() {
    const acceptTermsValid = this.state.acceptTerms;

    const errors = this.state.errors;
    errors.acceptTerms = acceptTermsValid
      ? ""
      : "Please agree to the Terms of Service and Privacy Policy.";
    this.setState({ errors });

    return acceptTermsValid;
  }

  public handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const fieldName = e.target.name;
    const value = e.target.value;
    if (fieldName === "username") {
      this.setState({ username: value });
    } else if (fieldName === "email") {
      this.setState({ email: value });
    } else if (fieldName === "password") {
      this.setState({ password: value });
    } else if (fieldName === "acceptTerms") {
      this.setState({ acceptTerms: e.target.checked });
    }
  }

  public render() {
    const errors = Object.keys(this.state.errors)
      .filter((key: string) => this.state.errors[key])
      .map((key: string) => {
        return (
          // tslint:disable-next-line:jsx-key
          <div className="signin__form__error">{this.state.errors[key]}</div>
        );
      });
    return (
      <div className="l-loginless-container">
        <LoginlessHeader />
        <div className="signin">
          <div className="signin__title">Sign Up</div>
          <p>Please sign-up Uo･ｪ･oU</p>
          <div className="signin__wrap">
            <div className="signin__google-auth">
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth}
              />
            </div>
            <Divider />
            <form className="signin__form" autoComplete="off">
              <div>
                <TextField
                  name="username"
                  label="Username"
                  className="signin__form__input"
                  value={this.state.username}
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <TextField
                  name="email"
                  label="Email"
                  className="signin__form__input"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <TextField
                  name="password"
                  label="Password"
                  type="password"
                  className="signin__form__input"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </div>
              {errors}
              <div />
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="acceptTerms"
                      checked={this.state.acceptTerms}
                      onChange={this.handleChange}
                      color="primary"
                      value={this.state.acceptTerms}
                      inputProps={{
                        "aria-label": "Checkbox A",
                      }}
                    />
                  }
                  label={
                    <span className="signin__form__checkboxlabel">
                      I agree to the{" "}
                      <Link to="terms" target="blank">
                        Terms of Service
                      </Link>{" "}
                      and{" "}
                      <Link to="privacy" target="blank">
                        Privacy Policy
                      </Link>
                    </span>
                  }
                />
              </div>
              <div>
                <Button
                  variant="outlined"
                  // color="secondary"
                  className="signin__form__submit"
                  onClick={this.onSubmit}
                >
                  {`${this.state.loading ? "Signing up..." : "Sign Up"}`}
                </Button>
              </div>
              <p className="signin__caution">
                You can use it for free during the beta version
              </p>
              <div>
                <Link to="/signin">Have an account? Log in</Link>
              </div>
            </form>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;
