import TUTORIAL from "../../constants/tutorial";
import RowStruct from "../../models/RowStruct";
import { db } from "../firebase";
import * as util from "./util";

export const initFirstBoard = async (userId: string) => {
  let isFirstBoard = false;
  await db
    .collection("boards")
    .where("userId", "==", userId)
    .get()
    .then((querySnapshot) => {
      // emptyでかつキャッシュもみてなけれｂないと言える
      isFirstBoard = !(
        querySnapshot.empty && !querySnapshot.metadata.fromCache
      );
    });

  if (isFirstBoard) {
    // console.warn("board already exists");
    return;
  }

  await db.collection("boards").add(
    util.addTimeStamp({
      today: "",
      todo: TUTORIAL,
      userId,
    })
  );
};

// boardが一個だけの前提
export const findOne = (userId: string) => {
  return db
    .collection("boards")
    .where("userId", "==", userId)
    .orderBy("timestamp", "desc")
    .limit(1)
    .get()
    .then((querySnapshot) => {
      return querySnapshot.docs[0];
    });
};

export const findById = (id: string) => {
  return db.collection("boards").doc(id);
};

export const getFinishedTodo = (boardId: string) => {
  return db
    .collection(`boards/${boardId}/done`)
    .orderBy("finishedDate", "desc")
    .limit(5 * 7) // FIXME: paging & daily grouped cache for improving reaad counts
    .get()
    .then((querySnapshot) => {
      return querySnapshot.docs;
    });
};

export const addDone = (boardId: string, doneTodos: RowStruct[]) => {
  const doneSubCollection = db.collection(`boards/${boardId}/done`);

  const batch = db.batch();

  doneTodos.forEach((todo) => {
    if (todo.isDone()) {
      const newDone = doneSubCollection.doc();
      batch.set(
        newDone,
        util.addTimeStamp({
          estimation: todo.estimation,
          finishedDate: todo.finishedDate,
          performance: todo.performance,
          rawText: todo.toRawText(),
          title: todo.title,
          // finishedAt: TODO: save correct finished date
        })
      );
    }
  });

  return batch.commit().then((res) => {
    // console.warn(res);
  });
};
