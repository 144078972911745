import * as React from "react";
import Logo from "../../assets/images/tododoc_logo.png";
import "../../styles/header.css";

// tslint:disable-next-line:no-empty-interface
interface Props {}

export default class LoginlessHeader extends React.Component<Props, any> {

  constructor(props: Props) {
    super(props);
  }

  public render() {
    return (
      <div className={`header`}>
        <h1 className="header__title">
        <img className="header__logo" src={Logo} alt="" />
        </h1>
      </div>
    );
  }
}
